import React, {Component} from "react";
import {Col, Container, Row} from "reactstrap";
import {setupReduxConnection} from "../../redux";
import LoginPhoneForm from "../../forms/login/login-phone-form";
import LoginTokenForm from "../../forms/login/login-token-form";
import {Check} from "react-feather";

class LoginPage extends Component {

  renderForm() {
    let {isLoading, isCompleted, unique} = this.props.entry;

    if (isCompleted) {
      return (
        <div className="loader-box">
          <Check size={64} color="green" />
        </div>
      );
    }

    if (isLoading) {
      return (
        <div className="loader-box">
          <div className="loader-2" />
        </div>
      );
    }

    if (unique === null) {
      return <LoginPhoneForm {...this.props} />;
    }

    return <LoginTokenForm {...this.props} />;
  }

  render() {
    return (
      <Container fluid={true}>
        <div className="authentication-main mt-0">
          <Row>
            <Col xs="12">
              <div className="login-card">
                <div>
                  <div>
                    <a className="logo">
                      <img
                        className="img-fluid for-light"
                        src={require("../../assets/images/logo/DriposLogo.png")}
                        alt="looginpage"
                      />
                      <img
                        className="img-fluid for-dark"
                        src={require("../../assets/images/logo/DriposLogoDark.png")}
                        alt="looginpage"
                      />
                    </a>
                  </div>
                  <div className="login-main">{this.renderForm()}</div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    );
  }
}

export default setupReduxConnection(["entry"])(LoginPage);
