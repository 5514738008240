export const PRIMARY_COLOR = "#7366ff";
export const SECONDARY_COLOR = "#f73164";

export const TICKET_PLATFORMS = ["Mobile", "Point of Sale", "Kiosk", "Web"];
export const TICKET_TRANSACTION_TYPES = [
  "Mobile Card",
  "Card",
  "Cash",
  "Tab",
  "Gift Card",
  "Other",
  "Invoice",
  "Reward",
  "Split",
  "Drip Balance",
];

export const COLORS = {
  PRIMARY_COLOR: "#7366ff",
  SECONDARY_COLOR: "#f73164",
  DRIP_GOLD: "#b99438",
  DRIP_MAIN: "#4ACE9C",
  CORK_BLUE: "#326489",
  SOON_ORANGE: "#FFAC5D",
  ORANGE: "#D56D44",
  BACKGROUND: "#F6F6F6",
  RED: "#AA3939",
  DRIP_GRAY: "#242426",
  TEXT_BLACK: "#171717",
  TEXT_GRAY: "#ADADAD",
  TEXT_LIGHT_GRAY: "#868686",
  TEXT_DARK_GRAY: "#808080",
  MONEY_GREEN: "#1C9115",
  DRIP_GREEN: "#4ACE9C",
  GRAYED_OUT: "#F4F4F4",
  BUTTON_DISABLED: "#C6C6C6",
  BORDER: "#E6E6E6",
};
