export function getURL() {
  if (window.location.href.indexOf("qe.order.drip.is") !== -1) {
    return "https://qe.api.drip.is/";
  }

  if (window.location.href.indexOf("localhost") === -1) {
    return "https://api.dripdrinks.co/";
  }

  return "http://localhost:6969/api/";
}

export function getEnvironment() {
  if (window.location.href.indexOf("qe.order.drip.is") !== -1) {
    return "qe";
  }

  if (window.location.href.indexOf("localhost") === -1) {
    return "production";
  }

  return "test";
}

export function getShortURL() {
  if (window.location.href.indexOf("qe.order.drip.is") !== -1) {
    return "https://qe.api.drip.is/";
  }

  if (window.location.href.indexOf("localhost") === -1) {
    return "https://api.dripdrinks.co/";
  }

  return "http://localhost:6969/";
}

export function getStripeID() {
  if (window.location.href.indexOf("qe.order.drip.is") !== -1) {
    return "pk_test_SJXSgpblYrYssIxYRBbWemYg";
  }

  if (window.location.href.indexOf("localhost") === -1) {
    return "pk_live_OQCRXlIgLqB8vC3TQaKr7d6Y";
  }

  return "pk_test_SJXSgpblYrYssIxYRBbWemYg";
}

export function request(endpoint, method, body, callback = function () {}) {
  return new Promise((resolve, reject) => {
    let headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    let params = "";
    if (method === "GET" && body) {
      // Trim out null or empty strings
      for (var prop in body) {
        if (body[prop] === null || body[prop] === undefined || body[prop] === "") {
          delete body[prop];
        }
      }
      params =
        "?" +
        Object.keys(body)
          .map((item) => {
            return item + "=" + encodeURIComponent(body[item]);
          })
          .join("&");
      body = null;
    }

    if (typeof window.localStorage["TOKEN"] !== "undefined") {
      headers["Authentication"] = window.localStorage["TOKEN"];
    }

    if (body !== null) {
      fetch(getURL() + endpoint, {
        method: method,
        headers: headers,
        body: JSON.stringify(body),
        credentials: "same-origin",
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson["success"]) {
            callback(undefined, responseJson.data);
            resolve(responseJson.data);
          } else {
            callback(responseJson, responseJson.code);
            reject(responseJson);
          }
        })
        .catch((error) => {
          callback(error, endpoint);
          reject(error);
        });
    } else {
      fetch(getURL() + endpoint + params, {
        method: method,
        headers: headers,
        credentials: "same-origin",
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson["success"]) {
            callback(undefined, responseJson.data);
            resolve(responseJson.data);
          } else {
            callback(responseJson, responseJson.code);
            reject(responseJson);
          }
        })
        .catch((error) => {
          callback(JSON.stringify(error), endpoint);
          reject(error);
        });
    }
  });
}

export function stripeUploadFile(file, purpose) {
  return new Promise((resolve, reject) => {
    let headers = {
      Authorization: 'Bearer ' + 'pk_test_SJXSgpblYrYssIxYRBbWemYg'
    };

    let formData = new FormData();
    formData.append('file', file);
    formData.append('purpose', purpose);
    const config = {
      method: "POST",
      headers: headers,
      body: formData,
    };

    fetch("https://uploads.stripe.com/v1/files", config)
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson["id"]) {
          resolve(responseJson);
        } else {
          reject(responseJson);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export function uploadImage(endpoint, image) {
  return new Promise((resolve, reject) => {
    let headers = {};
    if (typeof window.localStorage["TOKEN"] !== "undefined") {
      headers["Authentication"] = window.localStorage["TOKEN"];
    }

    let formData = new FormData();
    formData.append("file", image);

    const config = {
      method: "POST",
      headers: headers,
      body: formData,
    };

    fetch(getURL() + endpoint, config)
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson["success"]) {
          resolve(responseJson.data);
        } else {
          reject(responseJson);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
}
