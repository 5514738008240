import SweetAlert from "sweetalert2";

const DEFAULT_DELAY = 500;

//
export function asyncDelay(start, delay = DEFAULT_DELAY) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, Math.min(delay - (new Date().getTime() - start), delay));
  });
}

export async function asyncTimeout(timeout) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, timeout);
  });
}

export function decimalToDollars(str) {
  if (!str) {
    return 0;
  }

  return Math.round(100 * parseFloat(str.replace(/[$,]/g, "")));
}

function addCommas(dollar) {
  return dollar.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function toDollars(cents, includeSign = false) {
  let str = "" + Math.ceil(Math.abs(cents));

  let prefix = includeSign ? "$" : "";
  if (cents < 0) {
    prefix = prefix + "-";
  }

  switch (str.length) {
    default:
      return (
        prefix +
        addCommas(str.substring(0, str.length - 2)) +
        "." +
        str.substring(str.length - 2, str.length)
      );
    case 0:
      return prefix + "0.00";
    case 1:
      return prefix + "0.0" + str;
    case 2:
      return prefix + "0." + str;
  }
}

export function parseIdDict(arr, key = "ID") {
  return arr.reduce((dict, item) => {
    dict[item[key]] = item;
    return dict;
  }, {});
}

export function randomString(howMany, chars) {
  chars = chars
    || "abcdefghijklmnopqrstuwxyzABCDEFGHIJKLMNOPQRSTUWXYZ0123456789";
  var rnd = crypto.randomBytes(howMany)
    , value = new Array(howMany)
    , len = chars.length;

  for (var i = 0; i < howMany; i++) {
    value[i] = chars[rnd[i] % len]
  }

  return value.join('');
};

export function capitalize(str){
  return str[0].toUpperCase() + str.slice(1);
}

export function stripeIdToLabel(id){
  console.log(id);
  id = id.replaceAll("_", " ");
  return id.replace(/\b./g, function (m) {
    return m.toUpperCase();
  });
}

export function hasStripeAccount(){
  return !(window.localStorage["CONNECT_ID"] == "null")
}

export function numberToBoolean(number){
  return number === 1 || number === "1";
}
