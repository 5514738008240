import PropTypes from "prop-types";
import React, {Component} from "react";
import {
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";

class SearchBox extends Component {
  render() {
    let {placeholder = "Search...", faIcon = "fa-search", onChange} = this.props;

    if(!onChange){
      alert("Error: SearchBox requires onChange prop.")
    }

    return (
      <InputGroup>
        <InputGroupAddon addonType="prepend">
          <InputGroupText>
            <i class={`fa ${faIcon}`} aria-hidden="true" style={{fontSize: 14}} />
          </InputGroupText>
        </InputGroupAddon>

        <Input
          placeholder={placeholder}
          onChange={(e) => onChange(e.target.value)}
        />
      </InputGroup>
    );
  }
}

SearchBox.propTypes = {
  onChange: PropTypes.func.isRequired,
  faIcon: PropTypes.string,
  placeholder: PropTypes.string
}

export default SearchBox;
