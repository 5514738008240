import React, {Component} from "react";
import Breadcrumb from "../layouts/breadcrumb/breadcrumb";
import {Card, CardBody, Col, Container, Row} from "reactstrap";
import {setupReduxConnection} from "../redux";
import {request} from "../utils/request";
import CountWidget from "../components/data-display/countup-widget/count-widget";
import {
  Coffee,
  HardDrive,
  Map,
  MapPin,
  Monitor,
  ShoppingCart,
  Sidebar,
  Smartphone,
} from "react-feather";
import LogoValueCard from "../components/data-display/logo-value-card";
import {toDollars} from "../utils/util";
import RibbonCard from "../components/data-display/ribbon-card";
import CountUp from "react-countup";
import DateRangeModal from "../modals/date-range-modal";
import ApexCharts from "react-apexcharts";
import moment from "moment-timezone";

class DashboardPage extends Component {
  state = {
    fetched: false,
    companyCount: 0,
    locationCount: 0,
    orderCount: 0,
    itemCount: 0,
    productCount: 0,
    processed: null,
    dailyTickets: 0,
    lastWeekDailyTickets: 0,
    weekTickets: null,
    lastWeekTickets: null,

    start: null,
    end: null,
  };

  componentDidMount = async () => {
    await this.fetchStatistics(moment().startOf("month").valueOf());
  };

  fetchStatistics = async (start = null, end = null) => {
    let {
      COMPANY_COUNT,
      LOCATION_COUNT,
      ORDER_COUNT,
      PRODUCT_COUNT,
      ITEM_COUNT,
      PROCESSED,
      DAILY_TICKETS,
      LAST_DAILY_TICKETS,
      WEEK_TICKETS,
      LAST_WEEK_TICKETS,
    } = await request("admin/dashboard/homepage", "GET", {
      DATE_START: start,
      DATE_END: end,
      TIMEZONE: moment.tz.guess(),
    });

    this.setState({
      fetched: true,
      companyCount: COMPANY_COUNT,
      locationCount: LOCATION_COUNT,
      orderCount: ORDER_COUNT,
      productCount: PRODUCT_COUNT,
      itemCount: ITEM_COUNT,
      processed: PROCESSED,
      tickets: DAILY_TICKETS,
      lastTickets: LAST_DAILY_TICKETS,
      weekTickets: WEEK_TICKETS,
      lastWeekTickets: LAST_WEEK_TICKETS,
    });
  };

  fetchSeries() {
    let {tickets, lastTickets} = this.state;

    let dayValue = moment().startOf("day").valueOf();
    let prevValue = moment().subtract(1, "week").startOf("day").valueOf();

    let ordersToday = tickets;
    let ordersPrev = lastTickets;

    let dayWindows = [],
      prevWindows = [];
    for (let i = 0; i < 24; i += 2) {
      let dayWindowEnd = moment(dayValue).add(2, "hours").valueOf();

      dayWindows.push(
        ordersToday.filter((item) => {
          return (
            dayValue < item.DATE_CREATED && dayWindowEnd > item.DATE_CREATED
          );
        }).length
      );

      let prevWindowEnd = moment(prevValue).add(2, "hours").valueOf();
      prevWindows.push(
        ordersPrev.filter((item) => {
          return (
            prevValue < item.DATE_CREATED && prevWindowEnd > item.DATE_CREATED
          );
        }).length
      );

      dayValue = dayWindowEnd;
      prevValue = prevWindowEnd;
    }

    return [
      {
        name: "Today",
        data: dayWindows,
      },
      {
        name: "Last " + moment().format("dddd"),
        data: prevWindows,
      },
    ];
  }

  renderOverviewStats = () => {
    let {
      companyCount,
      locationCount,
      itemCount,
      productCount,
      tickets,
      lastTickets,
      weekTickets,
      lastWeekTickets,
    } = this.state;

    const ticketTotal = tickets.reduce((accum, item) => {
      return accum + item.TOTAL;
    }, 0);

    const lastWeekNow = moment().subtract(1, "weeks");
    const lastTotal = lastTickets
      .filter((item) => {
        return item.DATE_CREATED < lastWeekNow;
      })
      .reduce((accum, item) => {
        return accum + item.TOTAL;
      }, 0);

    const weekTotal = weekTickets.reduce((accum, item) => {
      return accum + item.TOTAL;
    }, 0);

    const lastWeekTotal = lastWeekTickets.reduce((accum, item) => {
      return accum + item.TOTAL;
    }, 0);

    const weekTicket = weekTickets.reduce((accum, item) => {
      return accum + item.TICKETS;
    }, 0);

    const lastWeekTicket = lastWeekTickets.reduce((accum, item) => {
      return accum + item.TICKETS;
    }, 0);

    return (
      <Row>
        <Col xl="3">
          <CountWidget
            label="Active Companies"
            value={companyCount}
            icon={Map}
            decimal={0}
          />
        </Col>

        <Col xl="3">
          <CountWidget
            label="Active Locations"
            value={locationCount}
            icon={MapPin}
            decimal={0}
          />
        </Col>

        <Col xl="3">
          <CountWidget
            label="Products Created"
            value={productCount}
            icon={Coffee}
            decimal={0}
          />
        </Col>

        <Col xl="3">
          <CountWidget
            label="Items Created"
            value={itemCount}
            icon={ShoppingCart}
            decimal={0}
          />
        </Col>

        <Col xl="12 xl-100" className="dashboard-sec box-col-12">
          <Card className="earning-card">
            <CardBody className="p-0">
              <Row className="m-0">
                <Col xl="3" className="earning-content p-0">
                  <Row className="m-0 chart-left">
                    <Col xl="12" className="p-0 left_side_earning">
                      <h5>Dashboard</h5>
                      <p className="font-roboto">Overview of last two weeks</p>
                    </Col>

                    <Col xl="12" className="p-0 left_side_earning">
                      <h5>${toDollars(ticketTotal)}</h5>
                      <p className="font-roboto">Today's earnings</p>
                    </Col>

                    <Col xl="12" className="p-0 left_side_earning">
                      <h5>${toDollars(lastTotal)}</h5>
                      <p className="font-roboto">Last {moment().format("dddd")}'s earnings</p>
                    </Col>

                    <Col xl="12" className="p-0 left_side_earning">
                      <h5>
                        {(
                          ((ticketTotal - lastTotal) / lastTotal) *
                          100
                        ).toFixed(0)}
                        %
                      </h5>
                      <p className="font-roboto">Today's growth</p>
                    </Col>
                  </Row>
                </Col>

                <Col xl="9" className="p-0">
                  <div className="chart-right">
                    <Row className="m-0 p-tb">
                      <Col xl="9" md="9" sm="9" className="col-12 p-0" />

                      <Col
                        xl="3"
                        md="3"
                        sm="3"
                        className="col-12 p-0 justify-content-end"
                      >
                        <div className="inner-top-right">
                          <ul className="list-unstyled">
                            <li>Today</li>

                            <br />

                            <li>Last {moment().format("dddd")}</li>
                          </ul>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xl="12">
                        <CardBody className="p-0">
                          <div className="current-sale-container">
                            <ApexCharts
                              key={0}
                              style={{zIndex: 10000000}}
                              id="chart-currently"
                              options={{
                                chart: {
                                  height: 240,
                                  type: "area",
                                  toolbar: {
                                    show: false,
                                  },
                                },
                                dataLabels: {
                                  enabled: false,
                                },
                                stroke: {
                                  curve: "smooth",
                                },
                                xaxis: {
                                  type: "category",
                                  low: 0,
                                  offsetX: 0,
                                  offsetY: 0,
                                  show: false,
                                  categories: [
                                    "12am-2am",
                                    "2am-4am",
                                    "4am-6am",
                                    "6am-81m",
                                    "8am-10am",
                                    "10am-12pm",
                                    "12pm-2pm",
                                    "2pm-4pm",
                                    "4pm-6pm",
                                    "6pm-8pm",
                                    "8pm-10pm",
                                    "10pm-12am",
                                  ],
                                  labels: {
                                    low: 0,
                                    offsetX: 0,
                                    show: false,
                                  },
                                  axisBorder: {
                                    low: 0,
                                    offsetX: 0,
                                    show: false,
                                  },
                                  axisTicks: {
                                    show: false,
                                  },
                                },
                              }}
                              series={this.fetchSeries()}
                              type="area"
                              height={240}
                            />
                          </div>
                        </CardBody>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>

        <Col xl="6 xl-100" className="dashboard-sec box-col-12">
          <Card className="earning-card">
            <CardBody className="p-0">
              <Row className="m-0">
                <Col xl="3" className="earning-content p-0">
                  <Row className="m-0 chart-left">
                    <Col xl="12" className="p-0 left_side_earning">
                      <h5>Weekly Dashboard</h5>
                      <p className="font-roboto">Overview of last two weeks</p>
                    </Col>

                    <Col xl="12" className="p-0 left_side_earning">
                      <h5>${toDollars(weekTicket)}</h5>
                      <p className="font-roboto">Weekly's Tickets</p>
                    </Col>

                    <Col xl="12" className="p-0 left_side_earning">
                      <h5>${toDollars(lastWeekTicket)}</h5>
                      <p className="font-roboto">Last week's tickets</p>
                    </Col>

                    <Col xl="12" className="p-0 left_side_earning">
                      <h5>
                        {(
                          ((weekTicket - lastWeekTicket) / lastWeekTicket) *
                          100
                        ).toFixed(0)}
                        %
                      </h5>
                      <p className="font-roboto">Weekly ticket growth</p>
                    </Col>
                  </Row>
                </Col>

                <Col xl="9" className="p-0">
                  <div className="chart-right">
                    <Row className="m-0 p-tb">
                      <Col xl="9" md="9" sm="9" className="col-12 p-0" />

                      <Col
                        xl="3"
                        md="3"
                        sm="3"
                        className="col-12 p-0 justify-content-end"
                      >
                        <div className="inner-top-right">
                          <ul className="list-unstyled">
                            <li>Today</li>

                            <br />

                            <li>Last {moment().format("dddd")}</li>
                          </ul>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xl="12">
                        <CardBody className="p-0">
                          <div className="current-sale-container">
                            <ApexCharts
                              key={0}
                              style={{zIndex: 10000000}}
                              id="chart-currently"
                              options={{
                                chart: {
                                  height: 240,
                                  type: "area",
                                  toolbar: {
                                    show: false,
                                  },
                                },
                                dataLabels: {
                                  enabled: false,
                                },
                                stroke: {
                                  curve: "smooth",
                                },
                                xaxis: {
                                  type: "category",
                                  low: 0,
                                  offsetX: 0,
                                  offsetY: 0,
                                  show: false,
                                  categories: [
                                    moment().subtract(6, "days").format("dddd"),
                                    moment().subtract(5, "days").format("dddd"),
                                    moment().subtract(4, "days").format("dddd"),
                                    moment().subtract(3, "days").format("dddd"),
                                    moment().subtract(2, "days").format("dddd"),
                                    moment().subtract(1, "days").format("dddd"),
                                    moment().subtract(0, "days").format("dddd"),
                                  ],
                                  labels: {
                                    low: 0,
                                    offsetX: 0,
                                    show: false,
                                  },
                                  axisBorder: {
                                    low: 0,
                                    offsetX: 0,
                                    show: false,
                                  },
                                  axisTicks: {
                                    show: false,
                                  },
                                },
                              }}
                              series={[
                                {
                                  name: "This Week",
                                  data: weekTickets.map((item) => item.TICKETS),
                                },
                                {
                                  name: "Last Week",
                                  data: lastWeekTickets.map(
                                    (item) => item.TICKETS
                                  ),
                                },
                              ]}
                              type="area"
                              height={240}
                            />
                          </div>
                        </CardBody>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>

        <Col xl="6 xl-100" className="dashboard-sec box-col-12">
          <Card className="earning-card">
            <CardBody className="p-0">
              <Row className="m-0">
                <Col xl="3" className="earning-content p-0">
                  <Row className="m-0 chart-left">
                    <Col xl="12" className="p-0 left_side_earning">
                      <h5>Dashboard</h5>
                      <p className="font-roboto">Overview of last two weeks</p>
                    </Col>

                    <Col xl="12" className="p-0 left_side_earning">
                      <h5>${toDollars(weekTotal)}</h5>
                      <p className="font-roboto">Weekly's earnings</p>
                    </Col>

                    <Col xl="12" className="p-0 left_side_earning">
                      <h5>${toDollars(lastWeekTotal)}</h5>
                      <p className="font-roboto">Last week's earnings</p>
                    </Col>

                    <Col xl="12" className="p-0 left_side_earning">
                      <h5>
                        {(
                          ((weekTotal - lastWeekTotal) / lastWeekTotal) *
                          100
                        ).toFixed(0)}
                        %
                      </h5>
                      <p className="font-roboto">Weekly revenue growth</p>
                    </Col>
                  </Row>
                </Col>

                <Col xl="9" className="p-0">
                  <div className="chart-right">
                    <Row className="m-0 p-tb">
                      <Col xl="9" md="9" sm="9" className="col-12 p-0" />

                      <Col
                        xl="3"
                        md="3"
                        sm="3"
                        className="col-12 p-0 justify-content-end"
                      >
                        <div className="inner-top-right">
                          <ul className="list-unstyled">
                            <li>Today</li>

                            <br />

                            <li>Last Week</li>
                          </ul>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xl="12">
                        <CardBody className="p-0">
                          <div className="current-sale-container">
                            <ApexCharts
                              key={0}
                              style={{zIndex: 10000000}}
                              id="chart-currently"
                              options={{
                                chart: {
                                  height: 240,
                                  type: "area",
                                  toolbar: {
                                    show: false,
                                  },
                                },
                                dataLabels: {
                                  enabled: false,
                                },
                                stroke: {
                                  curve: "smooth",
                                },
                                yaxis: {
                                  labels: {
                                    formatter: function (value) {
                                      return "$" + toDollars(value);
                                    }
                                  },
                                },
                                xaxis: {
                                  type: "category",
                                  low: 0,
                                  offsetX: 0,
                                  offsetY: 0,
                                  show: false,
                                  categories: [
                                    moment().subtract(6, "days").format("dddd"),
                                    moment().subtract(5, "days").format("dddd"),
                                    moment().subtract(4, "days").format("dddd"),
                                    moment().subtract(3, "days").format("dddd"),
                                    moment().subtract(2, "days").format("dddd"),
                                    moment().subtract(1, "days").format("dddd"),
                                    moment().subtract(0, "days").format("dddd"),
                                  ],
                                  labels: {
                                    low: 0,
                                    offsetX: 0,
                                    show: false,
                                  },
                                  axisBorder: {
                                    low: 0,
                                    offsetX: 0,
                                    show: false,
                                  },
                                  axisTicks: {
                                    show: false,
                                  },
                                },
                              }}
                              series={[
                                {
                                  name: "This Week",
                                  data: weekTickets.map((item) => item.TOTAL),
                                },
                                {
                                  name: "Last Week",
                                  data: lastWeekTickets.map(
                                    (item) => item.TOTAL
                                  ),
                                },
                              ]}
                              type="area"
                              height={240}
                            />
                          </div>
                        </CardBody>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  };

  renderProcessedData = () => {
    let {TOTAL, POS, WEB, MOBILE, KIOSK} = this.state.processed;
    let {orderCount} = this.state;

    return (
      <>
        <Row>
          <Col sm="6">
            <RibbonCard
              ribbonText="Orders Processed"
              ribbonBody={
                <>
                  <div className="f-40 text-center">
                    <CountUp decimals={0} end={orderCount} separator="," />
                  </div>
                </>
              }
            />
          </Col>

          <Col sm="6">
            <RibbonCard
              ribbonText="Total Processed"
              ribbonBody={
                <>
                  <div className="f-40 text-center">
                    <CountUp
                      prefix={"$"}
                      decimals={0}
                      end={parseInt(TOTAL / 100)}
                      separator=","
                    />
                  </div>
                </>
              }
            />
          </Col>
        </Row>
        <Row>
          <Col sm="6" xl="3" lg="6">
            <LogoValueCard
              title="POS"
              label="POS Processed"
              value={toDollars(POS, true)}
              logo={<HardDrive size={64} />}
            />
          </Col>

          <Col sm="6" xl="3" lg="6">
            <LogoValueCard
              title="Web"
              label="Web Processed"
              value={toDollars(WEB, true)}
              logo={<Monitor size={64} />}
            />
          </Col>

          <Col sm="6" xl="3" lg="6">
            <LogoValueCard
              title="Mobile"
              label="Mobile Processed"
              value={toDollars(MOBILE, true)}
              logo={<Smartphone size={64} />}
            />
          </Col>

          <Col sm="6" xl="3" lg="6">
            <LogoValueCard
              title="Kiosk"
              label="Kiosk Processed"
              value={toDollars(KIOSK, true)}
              logo={<Sidebar size={64} />}
            />
          </Col>
        </Row>
      </>
    );
  };

  render() {
    let {fetched, start, end} = this.state;

    return (
      <div>
        <DateRangeModal
          start={start}
          end={end}
          onChange={async ({start, end}) => {
            await this.fetchStatistics(start, end);

            this.setState({start, end});
          }}
          ref={(e) => (this.dateRangeModal = e)}
        />

        <Breadcrumb
          parent="Overview"
          title="Home"
          extraTitle={
            <button onClick={() => this.dateRangeModal.open()}>
              Select Range
            </button>
          }
        />

        <Container fluid={true}>
          {fetched && (
            <>
              {this.renderOverviewStats()}
              {this.renderProcessedData()}
            </>
          )}
        </Container>
      </div>
    );
  }
}

export default setupReduxConnection(["shop"])(DashboardPage);
