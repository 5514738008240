import React, {Component} from "react";
import {Formik} from "formik";
import * as Yup from "yup";
import {
  Button,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import {request} from "../../utils/request";
import PhoneFormGroup from "../../components/form-group/phone-form-group";
import PropTypes from "prop-types";

class PartnerModal extends Component {
  state = {open: false, creating: false, serverLocation: null};

  open(serverLocation) {
    this.setState({open: true, creating: false, serverLocation});
  }

  async addPartner({phone}) {
    const {locationId} = this.props;
    const {creating} = this.state;

    if (creating || !locationId) {
      return;
    }

    this.setState({creating: true});

    const serverPartner = await request(
      "admin/dashboard/location/" + locationId + "/partners",
      "POST",
      {PHONE: "1" + phone.replaceAll(" ", "")}
    );

    this.setState({open: false}, () => {
      this.props.onComplete(serverPartner);
    });
  }

  render() {
    const {open} = this.state;

    return (
      <Modal isOpen={open} toggle={() => this.setState({open: !open})} centered>
        <Formik
          onSubmit={this.addPartner.bind(this)}
          validationSchema={Yup.object().shape({
            phone: Yup.string().required("Please enter the partners phone"),
          })}
          initialValues={{
            phone: "",
          }}
        >
          {(formikOptions) => {
            let {handleSubmit} = formikOptions;

            return (
              <Form onSubmit={handleSubmit}>
                <ModalHeader toggle={() => this.setState({open: !open})}>
                  Add Partner to Location
                </ModalHeader>

                <ModalBody>
                  <PhoneFormGroup
                    options={formikOptions}
                    label="Partner Phone"
                    name="phone"
                  />
                </ModalBody>

                <ModalFooter>
                  <Button
                    type="button"
                    color="secondary"
                    onClick={() => this.setState({open: false})}
                  >
                    Cancel
                  </Button>

                  <Button color="primary" type="submit">
                    add plz
                  </Button>
                </ModalFooter>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

PartnerModal.propTypes = {
  onComplete: PropTypes.func,
  locationId: PropTypes.number.isRequired
}

export default PartnerModal;
