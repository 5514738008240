import React, {Component, Fragment} from "react";
import {ADMIN_MENU_ITEMS} from "../../routing/menu";
import {Link} from "react-router-dom";
import {ArrowLeft, ArrowRight, Grid} from "react-feather";

class Sidebar extends Component {
  state = {toggled: false, margin: 0, width: 0, menu: []};

  handleResize = () => {
    this.setState({width: window.innerWidth - 500});
  };

  componentDidMount() {
    const currentUrl = window.location.pathname;

    document.querySelector(".left-arrow").classList.add("d-none");
    window.addEventListener("resize", this.handleResize);

    let menuItems = ADMIN_MENU_ITEMS; // Default to Dripos Menu

    menuItems.map((items) => {
      items.Items.filter((Items) => {
        if (Items.path === currentUrl) {
          this.setNavActive(Items);
        }
        if (!Items.children) {
          return false;
        }
        Items.children.filter((subItems) => {
          if (subItems.path === currentUrl) this.setNavActive(subItems);
          if (!subItems.children) return false;
          subItems.children.filter((subSubItems) => {
            if (subSubItems.path === currentUrl) {
              this.setNavActive(subSubItems);
              return true;
            } else {
              return false;
            }
          });
          return subItems;
        });
        return Items;
      });
      return items;
    });

    this.setState({menu: menuItems});
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  toggleSidebar() {
    let {toggled} = this.state;

    if (!toggled) {
      document.querySelector(".page-header").className =
        "page-header close_icon";
      document.querySelector(".sidebar-wrapper").className =
        "sidebar-wrapper close_icon ";
    } else {
      document.querySelector(".page-header").className = "page-header";
      document.querySelector(".sidebar-wrapper").className = "sidebar-wrapper ";
    }

    this.setState({toggled: !toggled});
  }

  toggleNavActive = (item) => {
    let {menu} = this.state;

    if (window.innerWidth <= 991) {
      document.querySelector(".page-header").className =
        "page-header close_icon";
      document.querySelector(".sidebar-wrapper").className =
        "sidebar-wrapper close_icon ";
      if (item.type === "sub") {
        document.querySelector(".page-header").className = "page-header ";
        document.querySelector(".sidebar-wrapper").className =
          "sidebar-wrapper ";
      }
    }

    if (!item.active) {
      menu.map((a) => {
        a.Items.filter((Items) => {
          if (a.Items.includes(item)) Items.active = false;
          if (!Items.children) return false;
          Items.children.forEach((b) => {
            if (Items.children.includes(item)) {
              b.active = false;
            }
            if (!b.children) return false;
            b.children.forEach((c) => {
              if (b.children.includes(item)) {
                c.active = false;
              }
            });
          });
          return Items;
        });
        return a;
      });
    }

    item.active = !item.active;

    this.setState({menu: menu});
  };

  setNavActive = (item) => {
    let {menu} = this.state;

    menu.map((menuItems) => {
      menuItems.Items.filter((Items) => {
        if (Items !== item) Items.active = false;
        if (Items.children && Items.children.includes(item))
          Items.active = true;
        if (Items.children) {
          Items.children.filter((submenuItems) => {
            if (submenuItems.children && submenuItems.children.includes(item)) {
              Items.active = true;
              submenuItems.active = true;
              return true;
            } else {
              return false;
            }
          });
        }
        return Items;
      });
      return menuItems;
    });

    item.active = !item.active;
    this.setState({menu: menu});
  };

  render() {
    let {toggled, margin, width, menu} = this.state;

    const scrollToLeft = () => {
      if (margin >= -width) {
        this.setState({margin: 0});
        document.querySelector(".left-arrow").classList.add("d-none");
        document.querySelector(".right-arrow").classList.remove("d-none");
      } else {
        this.setState({margin: margin + width});
        document.querySelector(".right-arrow").classList.remove("d-none");
      }
    };

    const scrollToRight = () => {
      if (margin <= -2598 || margin <= -2034) {
        if (width === 492) {
          this.setState({margin: -3570});
        } else {
          this.setState({margin: -3464});
        }

        document.querySelector(".right-arrow").classList.add("d-none");
        document.querySelector(".left-arrow").classList.remove("d-none");
      } else {
        this.setState({margin: margin + -width});

        document.querySelector(".left-arrow").classList.remove("d-none");
      }
    };

    const responsiveSidebar = () => {
      document.querySelector(".page-header").className =
        "page-header close_icon";
      document.querySelector(".sidebar-wrapper").className =
        "sidebar-wrapper close_icon";
    };

    return (
      <div className="sidebar-wrapper">
        <div className="logo-wrapper">
          <Link to={`${process.env.PUBLIC_URL}/home`}>
            <img
              className="img-fluid for-light"
              src={require("../../assets/images/logo/DriposLogo.png")}
              alt=""
            />
            <img
              className="img-fluid for-dark"
              src={require("../../assets/images/logo/DriposLogoDark.png")}
              alt=""
            />
          </Link>
          <div className="back-btn" onClick={() => responsiveSidebar()}>
            <i className="fa fa-angle-left" />
          </div>
          <div
            className="toggle-sidebar"
            onClick={this.toggleSidebar.bind(this)}
          >
            <Grid className="status_toggle middle sidebar-toggle" />
          </div>
        </div>
        <div className="logo-icon-wrapper">
          <Link to={`${process.env.PUBLIC_URL}/home`}>
            <img
              className="img-fluid"
              src={require("../../assets/images/logo/logo-icon.png")}
              alt=""
            />
          </Link>
        </div>
        <nav className="sidebar-main">
          <div className="left-arrow" onClick={scrollToLeft}>
            <ArrowLeft />
          </div>
          <div
            id="sidebar-menu"
            style={
              "compact-wrapper" === "horizontal-wrapper"
                ? {marginLeft: margin + "px"}
                : {margin: "0px"}
            }
          >
            <ul className="sidebar-links custom-scrollbar">
              <li className="back-btn">
                <div className="mobile-back text-right">
                  <span>{"Back"}</span>
                  <i className="fa fa-angle-right pl-2" aria-hidden="true" />
                </div>
              </li>
              {menu.map((Item, i) => (
                <Fragment key={i}>
                  <li className="sidebar-main-title">
                    <div>
                      <h6 className="lan-1">{Item.title}</h6>
                      <p className="lan-2">{Item.content}</p>
                    </div>
                  </li>
                  {Item.Items.map((menuItem, i) => (
                    <li className="sidebar-list" key={i}>
                      {menuItem.type === "sub" ? (
                        <a
                          className={`sidebar-link sidebar-title  ${
                            menuItem.active ? "active" : ""
                          }`}
                          href="#javascript"
                          onClick={this.setNavActive.bind(this, menuItem)}
                        >
                          <menuItem.icon />
                          <span>{menuItem.title}</span>
                          {menuItem.badge ? (
                            <label className={menuItem.badge}>
                              {menuItem.badgetxt}
                            </label>
                          ) : (
                            ""
                          )}
                          <div className="according-menu">
                            {menuItem.active ? (
                              <i className="fa fa-angle-down"></i>
                            ) : (
                              <i className="fa fa-angle-right"></i>
                            )}
                          </div>
                        </a>
                      ) : (
                        ""
                      )}

                      {menuItem.type === "link" ? (
                        <Link
                          to={menuItem.path}
                          className={`sidebar-link sidebar-title link-nav  ${
                            menuItem.active ? "active" : ""
                          }`}
                          onClick={() => this.toggleNavActive(menuItem)}
                        >
                          <menuItem.icon />
                          <span>{menuItem.title}</span>
                          {menuItem.badge ? (
                            <label className={menuItem.badge}>
                              {menuItem.badgetxt}
                            </label>
                          ) : (
                            ""
                          )}
                        </Link>
                      ) : (
                        ""
                      )}

                      {menuItem.children ? (
                        <ul
                          className="sidebar-submenu"
                          style={
                            menuItem.active
                              ? toggled
                                ? {
                                    opacity: 1,
                                    transition: "opacity 500ms ease-in",
                                  }
                                : {display: "block"}
                              : {display: "none"}
                          }
                        >
                          {menuItem.children.map((childrenItem, index) => {
                            return (
                              <li key={index}>
                                {childrenItem.type === "sub" ? (
                                  <a
                                    className={`${
                                      childrenItem.active ? "active" : ""
                                    }`}
                                    href="#javascript"
                                    onClick={() =>
                                      this.toggleNavActive(childrenItem)
                                    }
                                  >
                                    {childrenItem.title}
                                    <span className="sub-arrow">
                                      <i className="fa fa-chevron-right"></i>
                                    </span>
                                    <div className="according-menu">
                                      {childrenItem.active ? (
                                        <i className="fa fa-angle-down"></i>
                                      ) : (
                                        <i className="fa fa-angle-right"></i>
                                      )}
                                    </div>
                                  </a>
                                ) : (
                                  ""
                                )}

                                {childrenItem.type === "link" ? (
                                  <Link
                                    to={childrenItem.path}
                                    className={`${
                                      childrenItem.active ? "active" : ""
                                    }`}
                                    onClick={() =>
                                      this.toggleNavActive(childrenItem)
                                    }
                                  >
                                    {childrenItem.title}
                                  </Link>
                                ) : (
                                  ""
                                )}

                                {childrenItem.children ? (
                                  <ul
                                    className="nav-sub-childmenu submenu-content"
                                    style={
                                      childrenItem.active
                                        ? {display: "block"}
                                        : {display: "none"}
                                    }
                                  >
                                    {childrenItem.children.map(
                                      (childrenSubItem, key) => (
                                        <li key={key}>
                                          {childrenSubItem.type === "link" ? (
                                            <Link
                                              to={childrenSubItem.path}
                                              className={`${
                                                childrenSubItem.active
                                                  ? "active"
                                                  : ""
                                              }`}
                                              onClick={() =>
                                                this.toggleNavActive(
                                                  childrenSubItem
                                                )
                                              }
                                            >
                                              {childrenSubItem.title}
                                            </Link>
                                          ) : (
                                            ""
                                          )}
                                        </li>
                                      )
                                    )}
                                  </ul>
                                ) : (
                                  ""
                                )}
                              </li>
                            );
                          })}
                        </ul>
                      ) : (
                        ""
                      )}
                    </li>
                  ))}
                </Fragment>
              ))}
            </ul>
          </div>

          <div className="right-arrow" onClick={scrollToRight}>
            <ArrowRight />
          </div>
        </nav>
      </div>
    );
  }
}

export default Sidebar;
