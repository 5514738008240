import React, {Component} from "react";
import {Formik} from "formik";
import * as Yup from "yup";
import {
  Button,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import InputFormGroup from "../components/form-group/input-form-group";
import {request} from "../utils/request";

class HandlePaydayModal extends Component {
  state = {open: false, paydayRow: null};

  open(paydayRow) {
    this.setState({open: true, paydayRow});
  }

  setPayday = async ({payday}) => {
    let {locationId, period_start, period_end} = this.state.paydayRow;

    let payload = {
      LOCATION_ID: locationId,
      PERIOD_START: period_start,
      PERIOD_END: period_end,
      NEW_PAYDAY: payday
    };

    await request("admin/preset", "POST", payload);
  }

  render() {
    const {open, paydayRow} = this.state;

    console.log(paydayRow)

    return (
      <Modal isOpen={open} toggle={() => this.setState({open: !open})} centered>
        <Formik
          onSubmit={this.setPayday}
          initialValues={{
            location: "",
          }}
        >
          {(formikOptions) => {
            let {handleSubmit} = formikOptions;

            return (
              <Form onSubmit={handleSubmit}>
                <ModalHeader toggle={() => this.setState({open: !open})}>
                  Preset Payday
                </ModalHeader>

                <ModalBody>
                  <div style={{marginBottom: 10}}>
                    <div style={{fontWeight: 500}}>
                      Location
                    </div>
                    <div>
                      {paydayRow?.locationName}
                    </div>
                  </div>

                  <div style={{marginBottom: 10}}>
                    <div style={{fontWeight: 500}}>
                      Period
                    </div>
                    <div>
                      {paydayRow?.period_start} to {paydayRow?.period_end}
                    </div>
                  </div>

                  <div style={{marginBottom: 10}}>
                    <div style={{fontWeight: 500}}>
                      Check Payday
                    </div>
                    <div>
                      {paydayRow?.payday}
                    </div>
                  </div>

                  <InputFormGroup
                    options={formikOptions}
                    label="Payday (in YYYY-MM-DD format)"
                    name="payday"
                  />
                </ModalBody>

                <ModalFooter>
                  <Button
                    type="button"
                    color="secondary"
                    onClick={() => this.setState({open: false})}
                  >
                    Cancel
                  </Button>

                  <Button color="primary" type="submit">
                    Save
                  </Button>
                </ModalFooter>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default HandlePaydayModal;
