import React, {Component} from "react";
import Breadcrumb from "../../layouts/breadcrumb/breadcrumb";
import {request} from "../../utils/request";
import {Container} from "reactstrap";
import SideFilter from "../../components/side-filter/side-filter";
import {UserCheck, UserX} from "react-feather";
import DataTable from "react-data-table-component";
import SelectFormGroup from "../../components/form-group/select-form-group";
import Fuse from "fuse.js";
import moment from "moment";
import HandlePaydayModal from "../../modals/handle-payday-modal";

class PayrollHolidayDatesPage extends Component {
  state = {loading: true, holidayPaydays: null, selectedLocationId: null};

  async componentDidMount() {
    let holidayPaydays = await request("admin/holiday-paydays", "GET")

    this.setState({loading: false, holidayPaydays});
  }

  filterHolidayPaydays = (filter) => {
    let {holidayPaydays, selectedLocationId} = this.state;

    holidayPaydays.sort((a, b) => moment(a.payday).valueOf() - moment(b.payday).valueOf());

    if (selectedLocationId) {
      holidayPaydays = holidayPaydays.filter(payday => payday.locationId === selectedLocationId);
    }

    return holidayPaydays;
  }

  renderCompanySelector = () => {
    let {holidayPaydays, selectedLocationId} = this.state;

    let locations = holidayPaydays.reduce((dict, holidayPayday) => {
      dict[holidayPayday.locationId] = holidayPayday.locationName;

      return dict;
    }, {});

    let locationOptions = [{
      label: "All",
      value: null
    }, ...Object.keys(locations).map(location => ({
      label: locations[location],
      value: location
    }))];

    return (
      <div className="m-t-25">
        <SelectFormGroup
          label="Location"
          name="location"
          data={locationOptions}
          value={selectedLocationId}
          onChange={(e) => {
            this.setState({
              selectedLocationId: e.target.value !== -1 ? parseInt(e.target.value) : null,
            });
          }}
        />
      </div>
    );
  };

  render() {
    let {loading} = this.state;

    return <>
      <HandlePaydayModal ref={e => this.handlePaydayModal = e}/>

      <Breadcrumb parent="Admin" title="Payroll Holiday Dates"/>

      <Container fluid={true}>

        {!loading && (
          <SideFilter
            filters={[
              {
                label: "Unhandled",
                value: 1,
              },
              {
                label: "Handled",
                value: 0,
              },
            ]}
            defaultFilter={1}
            // searchPlaceholder={"Search by Name or ID"}
            renderAfter={this.renderCompanySelector()}
          >
            {({filter, search}) => {
              let filteredHolidayPaydays = this.filterHolidayPaydays(filter);

              return (
                <DataTable
                  onRowClicked={(row) =>
                    this.handlePaydayModal.open(row)
                  }
                  columns={[
                    {
                      name: "Location Name",
                      selector: "locationName",
                    },
                    {
                      name: "Check Payday",
                      selector: "payday",
                      format: (row) => {
                        if(row.preset) return row.check_payday;
                        return row.payday;
                      }
                    },
                    {
                      name: "Set Payday",
                      selector: "payday",
                      format: (row) => {
                        if(row.preset) return row.payday;
                      }
                    }
                  ]}
                  data={filteredHolidayPaydays}
                  highlightOnHover
                  pointerOnHover
                  pagination
                  noHeader
                />
              );
            }}
          </SideFilter>
        )}
      </Container>
    </>
  }
}

export default PayrollHolidayDatesPage;
