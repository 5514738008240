import React, {Component} from "react";
import DataTable from "react-data-table-component";
import SideFilter from "../../components/side-filter/side-filter";
import Fuse from "fuse.js";
import PropTypes from "prop-types";
import {numberToBoolean} from "../../utils/util";
import PartnerSettingsModal from "../../modals/location/partner-settings-modal";

class LocationSettings extends Component {
  filterSettings = (search) => {
    let {partnerSettingsDict} = this.props;

    if (partnerSettingsDict) {
      let settingsArray = [];

      for (const [key, value] of Object.entries(partnerSettingsDict)) {
        settingsArray.push({
          KEY: key,
          VALUE: value,
        });
      }

      settingsArray = settingsArray.sort((a, b) => a.KEY.localeCompare(b.KEY));

      if (search && search.length > 0) {
        settingsArray = new Fuse(settingsArray, {
          keys: ["KEY"],
          useExtendedSearch: true,
          threshold: 0.5,
        })
          .search(search)
          .map(({item}) => item);
      }

      return settingsArray;
    } else {
      return [];
    }
  };

  render = () => {
    return (
      <>
        <PartnerSettingsModal
          ref={(e) => (this.partnerSettingsModal = e)}
          onUpdate={this.props.refreshSettings}
          locationId={this.props.locationId}
        />

        <SideFilter searchPlaceholder={"Search by Setting"}>
          {({search}) => {
            let locationData = this.filterSettings(search);

            return (
              <DataTable
                columns={[
                  {
                    name: "Setting",
                    selector: "KEY",
                    sortable: true,
                  },
                  {
                    name: "Value",
                    selector: "VALUE",
                    format: (row) => {
                      if (row.VALUE === "1" || row.VALUE === "0") {
                        return numberToBoolean(row.VALUE) ? "True" : "False";
                      } else {
                        return row.VALUE;
                      }
                    },
                  },
                ]}
                data={locationData}
                onRowClicked={(row) =>
                  this.partnerSettingsModal.open(row.KEY, row.VALUE)
                }
                highlightOnHover
                pointerOnHover
                defaultSortField="KEY"
                pagination
                noHeader
              />
            );
          }}
        </SideFilter>
      </>
    );
  };
}

LocationSettings.propTypes = {
  partnerSettingsDict: PropTypes.object.isRequired, // { _KEY: VALUE }
  locationId: PropTypes.number.isRequired,
  refreshSettings: PropTypes.func.isRequired, // function to refresh partner settings after update is made
};

export default LocationSettings;
