import React, {Component} from "react";
import {FormGroup, Label} from "reactstrap";
import DatePicker from "react-datepicker";
import PropTypes from "prop-types";

class DateFormGroup extends Component {
  render() {
    let {
      label,
      name,
      error,
      options = null,
      disabled = false,
      onChange,
      placeholder,
      value,
      onBlur,
      showTimeSelect = true,
      dateFormat ="Pp"
    } = this.props;

    return (
      <FormGroup>
        <Label className="form-label" for="recipient-name">
          {label}
        </Label>

        <DatePicker
          className="form-control digits"
          selected={options ? options.values[name] : value}
          disabled={disabled}
          name="start"
          onChange={(start) => {
            options ? options.setFieldValue(name, !start ? null : start.getTime()) : onChange(start);
          }}
          placeholderText={placeholder}
          onBlur={options ? options.handleBlur : onBlur}
          showTimeSelect={showTimeSelect}
          autoComplete="off"
          dateFormat="Pp"
          {...this.props}
        />

        <span style={{color: "red"}}>
          {options ? options.touched[name] && options.errors[name] : error}
        </span>
      </FormGroup>
    );
  }
}

DateFormGroup.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,

  placeholder: PropTypes.string,
  error: PropTypes.string,
  value: PropTypes.any,
  options: PropTypes.object,

  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
};

export default DateFormGroup;
