import {
  applyMiddleware,
  bindActionCreators,
  combineReducers,
  createStore,
  compose,
} from "redux";
import {connect} from "react-redux";
import thunk from "redux-thunk";

import {globalReducer} from "./global";
import {entryReducer} from "./entry";
import {shopReducer} from "./shop";

import * as GlobalActions from "./global";
import * as EntryActions from "./entry";
import * as ShopActions from "./shop";

let store = null;

const actionSanitizer = (action) => {
  return action.type === "UPDATE_TICKETS" ?
    { ...action, payload: {orders: []} } : action
}

export function setupStore() {
  if (!store) {
    store = createStore(
      combineReducers({
        global: globalReducer,
        entry: entryReducer,
        shop: shopReducer,
      }),
      compose(
        applyMiddleware(thunk),
        window.__REDUX_DEVTOOLS_EXTENSION__
          ? window.__REDUX_DEVTOOLS_EXTENSION__({
            actionSanitizer,
          })
          : (f) => f
      )
    );
  }

  return store;
}

export function getStore() {
  return store;
}

export function getActions() {
  return {
    ...GlobalActions,
    ...EntryActions,
    ...ShopActions,
  };
}

export function setupReduxConnection(getProps, mergeProps=null, connectOptions={}) {
  function mapStateToProps(state) {
    if (Array.isArray(getProps)) {
      return getProps.reduce((dict, item) => {
        dict[item] = state[item];
        return dict;
      }, {});
    }

    return getProps(state);
  }

  function mapDispatchToProps(dispatch) {
    return bindActionCreators(getActions(), dispatch);
  }

  return connect(mapStateToProps, mapDispatchToProps, mergeProps, connectOptions);
}