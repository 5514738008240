import React, {Component} from "react";
import {Card, CardBody} from "reactstrap";
import CountUp from "react-countup";
import PropTypes from "prop-types";
import {Info} from "react-feather";

class CountWidget extends Component {
  render() {
    const {label, value, prefix, suffix, decimal = 2, icon: Icon, toolTip="", backgroundColor=""} = this.props;

    const className = backgroundColor ? "b-r-4 card-body" : "bg-primary b-r-4 card-body";
    return (
      <Card className="o-hidden" title={toolTip}>
        <CardBody className={className} style={{backgroundColor: backgroundColor}}>
          <div className="media static-top-widget">
            <div className="align-self-center text-center">
              <Icon />
            </div>
            <div className="media-body">
              <span className="m-0">{label}</span>

              <h4 className="mb-0 counter">
                <CountUp prefix={prefix} suffix={suffix} decimals={(decimal || decimal === 0) ? decimal : 2} end={value} separator=","/>
              </h4>

              <Icon className="icon-bg"/>
            </div>
          </div>
        </CardBody>
      </Card>
    );
  }
}

CountWidget.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  icon: PropTypes.node.isRequired,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  decimal: PropTypes.number,
  toolTip: PropTypes.string,
  backgroundColor: PropTypes.string // hex
};

export default CountWidget;
