import DashboardPage from "../pages/home-page";
import TicketsPage from "../pages/tickets/tickets-page";
import LocationsPage from "../pages/locations/locations-page";
import NewLocationPage from "../pages/locations/new-location-page";
import LocationPage from "../pages/locations/location-page";
import AccountsPage from "../pages/accounts/accounts-page";
import AccountPage from "../pages/accounts/account-page";
import ProductUsagesPage from "../pages/usages/product-usages-page";
import PayrollHolidayDatesPage from "../pages/payroll/payroll-holiday-dates-page";

export const routes = [
  {path: "/home", component: DashboardPage},
  {path: "/tickets", component: TicketsPage},
  {path: "/locations", component: LocationsPage},
  {path: "/location/new", component: NewLocationPage},
  {path: "/location/:ID", component: LocationPage},
  {path: "/accounts", component: AccountsPage},
  {path: "/accounts/:ID", component: AccountPage},
  {path: "/usages", component: ProductUsagesPage},
  {path: "/payroll", component: PayrollHolidayDatesPage},
]
