import React, {Component} from "react";
import InputFormGroup from "../components/form-group/input-form-group";
import {Formik, Field} from "formik";
import * as Yup from "yup";
import {
  Button,
  Col,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from "reactstrap";
import SelectFormGroup from "../components/form-group/select-form-group";
import {request} from "../utils/request";
import InputMask from "react-input-mask";
import SweetAlert from "sweetalert2";
import DateFormGroup from "../components/form-group/date-form-group";

class DateRangeModal extends Component {
  state = {open: false, creating: false, task: null};

  open(task = null) {
    this.setState({open: true, creating: false, task});
  }

  render() {
    const {onChange, start, end}= this.props;
    const {open} = this.state;

    return (
      <Modal isOpen={open} toggle={() => this.setState({open: !open})} centered>
        <Formik
          onSubmit={(...props) => {
            this.setState({open: false});

            onChange(...props);
          }}
          initialValues={{
            start: start || null,
            end: end || null
          }}
        >
          {(formikOptions) => {
            let {handleSubmit, values} = formikOptions;
            this.handleSubmit = handleSubmit;

            return (
              <Form onSubmit={handleSubmit}>
                <ModalHeader>Date Range</ModalHeader>

                <ModalBody>
                  <DateFormGroup
                    label="Start"
                    name="start"
                    options={formikOptions}
                  />

                  <DateFormGroup
                    label="End"
                    name="end"
                    options={formikOptions}
                  />
                </ModalBody>

                <ModalFooter>
                  <Button
                    color="secondary"
                    onClick={() => this.setState({open: false})}
                  >
                    Later
                  </Button>

                  <Button color="primary" type="submit">
                    Save
                  </Button>
                </ModalFooter>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default DateRangeModal;
