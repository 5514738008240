import React, {Component} from "react";
import {Button, Form, FormGroup, Input, Label} from "reactstrap";
import InputMask from "react-input-mask";

class LoginPhoneForm extends Component {
  async sendPhone(e) {
    e.preventDefault();

    this.props.sendEntryPhone()
  }

  render() {
    let {phone, error} = this.props.entry;

    return (
      <Form className="theme-form" onSubmit={this.sendPhone.bind(this)}>
        <h4>Login</h4>
        <FormGroup>
          <Label className="col-form-label">Enter Phone</Label>

          <Input
            className="form-control"
            placeholder="123 456 7890"
            value={phone}
            onChange={(e) => this.props.updateEntryPhone(e.target.value)}
            type="tel"
            mask="999 999 9999"
            maskChar=" "
            tag={InputMask}
            required
          />
          {error ? (
            <div style={{color: "red", paddingTop: 5}}>{error}</div>
          ) : (
            <div />
          )}
        </FormGroup>
        <FormGroup className="mb-0">
          <div className="checkbox ml-3">
            <Input id="checkbox1" type="checkbox" />
            <Label className="text-muted" for="checkbox1">
              Remember me for 30 days
            </Label>
          </div>
          <Button color="primary" className="btn-block" type="submit">
            Send Token
          </Button>
        </FormGroup>
      </Form>
    );
  }
}

export default LoginPhoneForm;
