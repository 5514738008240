import React, {Component} from "react";
import {
  Container,
  Row,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Col,
  Media,
  FormGroup,
  Spinner,
  Button,
  Form
} from "reactstrap";
import Breadcrumbs from "../../layouts/breadcrumb/breadcrumb";
import {setupReduxConnection} from "../../redux";
import {request} from "../../utils/request";
import {getObjectImage} from "../../utils/asset-helper";
import MenuModal from "../../modals/location/menu-modal";
import PartnerModal from "../../modals/location/partner-modal";
import LocationSettings from "../../features/location/location-settings";
import DataTable from "react-data-table-component";
import {getFormattedPhone} from "../../utils/phone";
import {Trash} from "react-feather";
import {
  sweetAlertFailure,
  sweetAlertSuccess,
  sweetConfirm,
} from "../../utils/sweet-alert";
import ToggleFormGroup from "../../components/form-group/toggle-form-group";
import {Formik} from "formik";
import moment from "moment";
import SelectFormGroup from "../../components/form-group/select-form-group";
import InputFormGroup from "../../components/form-group/input-form-group";

class LocationPage extends Component {
  state = {
    location: null,
    partners: [],
    settings: [],
    fullLocationData: null,
    currentPayrun: null
  };

  async componentDidMount() {
    await this.handleFetchLocationInfo();

    await this.handleFetchPartners();
  }

  handleFetchLocationInfo = async () => {
    const {ID} = this.props.match.params;

    const serverLocation = await request(
      "admin/dashboard/location/" + ID,
      "GET",
      null
    );

    let currentPayrun = null;

    // currentPayrun = await request("admin/pay-schedule/" + ID, "GET");


    this.setState({
      location: serverLocation.location,
      settings: serverLocation.settings,
      fullLocationData: serverLocation,
      raw: serverLocation,
      currentPayrun
    });
  };

  handleFetchPartners = async () => {
    const {ID} = this.props.match.params;

    const serverPartners = await request(
      "admin/dashboard/location/" + ID + "/partners",
      "GET",
      null
    );

    this.setState({partners: serverPartners});
  };

  handlePartnerRemove = async (account) => {
    const {ID} = this.props.match.params;

    if (ID && account) {
      let confirmed = await sweetConfirm(
        `Remove Partner`,
        `Are you sure you want to remove ${account.FULL_NAME} as a partner?`,
        "warning",
        "Remove"
      );

      if (confirmed) {
        await request(`admin/partner/${ID}/${account.ACCOUNT_ID}`, "DELETE")
          .then(async () => {
            await this.handleFetchPartners();
            await sweetAlertSuccess("Partner Removed");
          })
          .catch(async (err) => {
            await sweetAlertFailure(
              "Removal Failed",
              "Please refresh the page and try again."
            );
          });
      }
    }
  };

  renderLocation() {
    let {location} = this.state;

    return (
      <Col xl={{size: 8, offset: 2}}>
        <Card>
          <CardBody>
            <Row className="mb-2">
              <div className="col-auto">
                <Media
                  className="img-100"
                  src={getObjectImage(location, "ICON", "app_icon.png")}
                />
              </div>
              <Col>
                <h3 className="mb-1">{location.NAME}</h3>
                <p className="mb-4">{location.COMPANY_NAME}</p>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    );
  }

  renderPartners() {
    let {partners} = this.state;

    return (
      <Col xl={{size: 8, offset: 2}}>
        <Card>
          <CardHeader>
            <h4 className="card-title mb-0">Partners</h4>
          </CardHeader>

          <CardBody>
            <DataTable
              columns={[
                {
                  name: "Account ID",
                  selector: "ACCOUNT_ID",
                  width: "150px",
                  sortable: true,
                },
                {
                  name: "Full Name",
                  selector: "FULL_NAME",
                  sortable: true,
                },
                {
                  name: "Phone",
                  selector: "PHONE",
                  format: (row) => getFormattedPhone(row.PHONE),
                },
                {
                  name: "Delete",
                  ignoreRowClick: true,
                  width: "120px",
                  cell: (row) => (
                    <Button
                      color="danger"
                      onClick={() => this.handlePartnerRemove(row)}
                    >
                      <Trash size={14}/>
                    </Button>
                  ),
                },
              ]}
              data={partners}
              defaultSortField="ACCOUNT_ID"
              pagination
              noHeader
            />
          </CardBody>

          <CardFooter
            className="text-right"
            style={{
              display: "flex",
              gap: 5,
              justifyContent: "flex-end",
            }}
          >
            <button
              onClick={() => this.partnerModal.open()}
              className="btn btn-primary"
              type="button"
            >
              Add Partner
            </button>
          </CardFooter>
        </Card>
      </Col>
    );
  }

  renderSettings() {
    let {settings} = this.state;

    return (
      <Col xl={{size: 8, offset: 2}}>
        <Card>
          <CardHeader>
            <h4 className="card-title mb-0">Location Settings</h4>
          </CardHeader>

          <CardBody>
            <LocationSettings
              partnerSettingsDict={settings}
              locationId={this.props.match.params?.ID}
              refreshSettings={this.handleFetchLocationInfo}
            />
          </CardBody>
        </Card>
      </Col>
    );
  }

  savePayrollSettings = async (values) => {
    let {ID, COMPANY_ID} = this.state.location;

    let locationPayload = {
      COMPANY_ID,
      PAYROLL_WHITELISTED: values.whitelisted,
      PAYROLL_CONSULTED: values.consulted,
      PAYROLL_SUBMITTED: values.submitted,
      PAYROLL_APPROVED: values.approved
    }

    await request("admin/location/" + ID, "PATCH", locationPayload);

    await this.handleFetchLocationInfo();
  }

  renderPayroll() {
    let {PAYROLL_WHITELISTED, PAYROLL_CONSULTED, PAYROLL_SUBMITTED, PAYROLL_APPROVED} = this.state.location;

    return (
      <Formik
        onSubmit={this.savePayrollSettings}
        initialValues={{
          whitelisted: PAYROLL_WHITELISTED,
          consulted: PAYROLL_CONSULTED,
          submitted: PAYROLL_SUBMITTED,
          approved: PAYROLL_APPROVED
        }}
      >
        {(formikOptions) => {
          let {handleSubmit} = formikOptions;

          return <Col xl={{size: 8, offset: 2}}>
            <Card>
              <CardHeader>
                <h4 className="card-title mb-0">Payroll Onboarding Flow</h4>
              </CardHeader>

              <CardBody><Form onSubmit={handleSubmit}>
                <ToggleFormGroup
                  label="Whitelisted"
                  name="whitelisted"
                  options={formikOptions}
                />

                <ToggleFormGroup
                  label="Consulted"
                  name="consulted"
                  options={formikOptions}
                />

                <ToggleFormGroup
                  label="Submitted"
                  name="submitted"
                  options={formikOptions}
                />

                <ToggleFormGroup
                  label="Approved"
                  name="approved"
                  options={formikOptions}
                />

                <Button color="primary" block type={"submit"}>
                  Save
                </Button>
              </Form>
              </CardBody>
            </Card>
          </Col>
        }}
      </Formik>
    );
  }

  createPaySchedule = async (values) => {
    let {ID} = this.state.location;

    await request("admin/pay-schedule/" + ID, "POST", values);

    await this.handleFetchLocationInfo();
  }

  deletePaySchedule = async () => {
    let {ID} = this.state.location;

    await request("admin/pay-schedule/" + ID, "DELETE");

    await this.handleFetchLocationInfo();
  }

  renderPaySchedule() {
    let {currentPayrun} = this.state;


    return <Formik
      onSubmit={this.createPaySchedule}
      initialValues={{
        TYPE: "BIWEEKLY"
      }}
    >
      {(formikOptions) => {
        let {handleSubmit} = formikOptions;

        return <Col xl={{size: 8, offset: 2}}>
          <Card>
            <CardHeader>
              <h4 className="card-title mb-0">{currentPayrun ? "View Pay Schedule" : "Create Pay Schedule"}</h4>
            </CardHeader>

            <CardBody>
              {currentPayrun && (
                <div>
                  <div>
                    Period Start: {moment(currentPayrun?.PERIOD_START).format("MM/DD/YYYY")}
                  </div>

                  <div>
                    Period End: {moment(currentPayrun?.PERIOD_END).format("MM/DD/YYYY")}
                  </div>

                  <div>
                    Payday: {moment(currentPayrun?.PAYDAY).format("MM/DD/YYYY")}
                  </div>

                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={async () => {
                      await this.deletePaySchedule();
                    }}
                  >
                    Delete Schedule
                  </button>
                </div>
              )}

              {!currentPayrun && <Form onSubmit={handleSubmit}>
                <SelectFormGroup
                  label="Type"
                  name="TYPE"
                  data={[
                    {
                      label: "Biweekly",
                      value: "BIWEEKLY"
                    },
                    {label: "Weekly", value: "WEEKLY"}
                  ]}
                  options={formikOptions}
                />

                <InputFormGroup
                  label="First Period End (Format: YYYY-MM-DD)"
                  name="FIRST_PERIOD_END"
                  options={formikOptions}
                />

                <InputFormGroup
                  label="First Payday (Format: YYYY-MM-DD)"
                  name="PAYDAY"
                  options={formikOptions}
                />

                <Button color="primary" block type={"submit"}>
                  Create
                </Button>
              </Form>}
            </CardBody>
          </Card>
        </Col>
      }}
    </Formik>
  }

  render() {
    let {location, raw} = this.state;

    return (
      <Container fluid={true}>
        <Breadcrumbs
          parentLink="/locations"
          parent="Locations"
          title={location?.NAME ?? "Loading"}
        />
        {!location ? (
          <div
            style={{display: "flex", justifyContent: "center", marginTop: 150}}
          >
            <Spinner/>
          </div>
        ) : (
          <>
            <PartnerModal
              ref={(e) => (this.partnerModal = e)}
              onComplete={this.handleFetchPartners}
              locationId={this.props.match.params?.ID}
            />

            <MenuModal ref={(e) => (this.menuModal = e)} {...this.props} />

            <Container fluid={true}>
              <FormGroup className="edit-profile">
                <Row>
                  <Form className="card" onSubmit={() => {
                  }}>
                    <CardHeader>
                      <h4 className="card-title mb-0">Categories</h4>
                    </CardHeader>

                    <CardBody>
                      {raw.categories.length > 0 ? (
                        raw.categories.map((item) => {
                          return <div>{item.NAME}</div>;
                        })
                      ) : (
                        <div>no categories = no menu :(</div>
                      )}
                    </CardBody>

                    <CardFooter
                      className="text-right"
                      style={{
                        display: "flex",
                        gap: 5,
                        justifyContent: "flex-end",
                      }}
                    >
                      {raw.categories.length === 0 && (
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() => {
                            this.menuModal.open(location);
                          }}
                        >
                          Pull Menu
                        </button>
                      )}
                    </CardFooter>
                  </Form>

                  {this.renderLocation()}

                  {this.renderPartners()}

                  {this.renderSettings()}

                  {this.renderPayroll()}

                  {this.renderPaySchedule()}
                </Row>
              </FormGroup>
            </Container>
          </>
        )}
      </Container>
    );
  }
}

export default setupReduxConnection(["shop"])

(
  LocationPage
)
;
