import React, {Component} from "react";
import {BreadcrumbItem, Breadcrumb, Col, Container, Row} from "reactstrap";
import {Link} from "react-router-dom";
import {Home} from "react-feather";
import PropTypes from "prop-types";

class Breadcrumbs extends Component {
  render() {
    let {title, extraTitle, parent, parentLink, parentState} = this.props;

    return (
      <Container fluid={true}>
        <div className="page-title">
          <Row>
            <Col xs="6">
              <h3>{title}</h3>

              {extraTitle}
            </Col>

            <Col xs="6">
              <Breadcrumb>
                <BreadcrumbItem>
                  <Link to={`${process.env.PUBLIC_URL}/home`}>
                    <Home />
                  </Link>
                </BreadcrumbItem>
                {parentLink ? (
                  <BreadcrumbItem>
                    <Link to={{pathname: process.env.PUBLIC_URL + parentLink, state: parentState}}>
                      {parent}
                    </Link>
                  </BreadcrumbItem>
                ) : (
                  <BreadcrumbItem>{parent}</BreadcrumbItem>
                )}{" "}
                <BreadcrumbItem active>{title}</BreadcrumbItem>
              </Breadcrumb>
            </Col>
          </Row>
        </div>
      </Container>
    );
  }
}

Breadcrumbs.propTypes = {
  title: PropTypes.string.isRequired,
  parent: PropTypes.string.isRequired,
  parentLink: PropTypes.string,
  parentState: PropTypes.object,
};

export default Breadcrumbs;
