const AWS_S3_URL = "https://drip-drinks.s3.amazonaws.com";

export function getObjectImage(obj, key, backup, low) {
    if (obj.hasOwnProperty(key) === false || obj[key] === null) {
        return getAssetUrl(backup);
    }

    return getAssetUrl((low ? "LOW_" : "") + obj[key]);
}

export function getAssetUrl(url) {
    if (url.startsWith("http")) {
        return url;
    }

    return AWS_S3_URL + "/" + url;
}
