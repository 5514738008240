import {asyncDelay} from "../utils/util";
import {request} from "../utils/request";

const defaultState = {
  locations: [],
  companies: [],
};

const UPDATE_SHOP = "UPDATE_SHOP";
const UPDATE_LOCATIONS = "UPDATE_LOCATIONS";
const UPDATE_COMPANIES = "UPDATE_COMPANIES";

export function updateShop(payload) {
  return {type: UPDATE_SHOP, payload};
}

export function updateLocations(payload) {
  return {type: UPDATE_LOCATIONS, payload};
}

export function updateCompanies(payload) {
  return {type: UPDATE_COMPANIES, payload};
}

export function addLocation(location) {
  return (dispatch, getState) => {
    const {locations} = getState().shop;

    dispatch(updateLocations([...locations, location]));
  }
}

export const shopReducer = (state = defaultState, action) => {
  let {type, payload} = action;

  switch (type) {
    default:
      return state;
    case UPDATE_SHOP:
      return {...state, ...payload};
    case UPDATE_LOCATIONS:
      return {...state, locations: payload};
    case UPDATE_COMPANIES:
      return {...state, companies: payload};
  }
};
