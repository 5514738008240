import React, {Component} from "react";
import {FormGroup, Input, Label} from "reactstrap";
import PropTypes from "prop-types";

class SelectFormGroup extends Component {
  render() {
    let {
      label,
      name,
      error,
      value,
      data,
      onChange,
      onBlur,
      disabled = false,
      options = null,
    } = this.props;

    return (
      <FormGroup>
        <Label for="recipient-name">
          {label}
        </Label>

        <Input
          name={name}
          type="select"
          className="form-control"
          onChange={options ? options.handleChange : onChange}
          onBlur={options ? options.handleBlur : onBlur}
          value={options ? options.values[name] : value}
          disabled={disabled}
        >
          {data.map(({label, value}) => (
            <option value={value}>{label}</option>
          ))}
        </Input>

        <span style={{color: "red"}}>
          {options ? options.touched[name] && options.errors[name] : error}
        </span>
      </FormGroup>
    );
  }
}

SelectFormGroup.propTypes = {
  label: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,

  error: PropTypes.string,
  value: PropTypes.any,
  options: PropTypes.object,

  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};

export default SelectFormGroup;
