import React, {Component} from "react";
import {Card, CardBody} from "reactstrap";
import PropTypes from "prop-types";

class RibbonCard extends Component {
  render() {
    let {ribbonText, ribbonBody} = this.props;

    return (
      <Card className="ribbon-wrapper">
        <CardBody>
          <div className="ribbon ribbon-clip ribbon-success">{ribbonText}</div>
          {ribbonBody}
        </CardBody>
      </Card>
    );
  }
}

RibbonCard.propTypes = {
  ribbonText: PropTypes.string.isRequired,
  ribbonBody: PropTypes.node.isRequired,
};

export default RibbonCard;