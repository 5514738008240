import {Home, Moon, Percent, Users} from "react-feather";
import {faMoneyBill} from "@fortawesome/pro-light-svg-icons";

export const ADMIN_MENU_ITEMS = [
  {
    title: "Overview",
    content: "General Dripos Information",
    Items: [
      {
        path: `${process.env.PUBLIC_URL}/home`,
        title: "Home",
        icon: Home,
        type: "link",
      },
    ],
  },
  {
    title: "Dripos Ecosystem",
    content: "General Dripos Information",
    Items: [
      {
        path: `${process.env.PUBLIC_URL}/locations`,
        title: "Locations",
        icon: Home,
        type: "link",
      },
    ],
  },
  {
    title: "Live Tickers",
    content: "Get live updates",
    Items: [
      {
        path: `${process.env.PUBLIC_URL}/ticker/tickets`,
        title: "Tickets Ticker",
        icon: Home,
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/live/items`,
        title: "Item Ticker",
        icon: Home,
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/live/accounts`,
        title: "Account Ticker",
        icon: Home,
        type: "link",
      },
    ],
  },
  {
    title: "Admin",
    content: "Get live updates",
    Items: [
      {
        path: `${process.env.PUBLIC_URL}/tickets`,
        title: "Tickets",
        icon: Home,
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/accounts`,
        title: "Accounts",
        icon: Users,
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/payroll`,
        title: "Payroll",
        icon: Moon,
        type: "link"
      },
      {
        path: `${process.env.PUBLIC_URL}/usages`,
        title: "Product Usages",
        icon: Percent,
        type: "link",
      },
    ],
  },
];
