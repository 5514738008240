import React, {Component} from "react";
import Breadcrumb from "../../layouts/breadcrumb/breadcrumb";
import {setupReduxConnection} from "../../redux";
import {Container} from "reactstrap";
import DataTable from "react-data-table-component";
import SideFilter from "../../components/side-filter/side-filter";
import {UserCheck, UserX} from "react-feather";
import Fuse from "fuse.js";
import SelectFormGroup from "../../components/form-group/select-form-group";

class LocationsPage extends Component {
  state = {
    companyIdFilter: null,
  };

  filterLocations = (enabledFilter, search) => {
    let {locations} = this.props.shop;
    let {companyIdFilter} = this.state;

    if (enabledFilter !== null) {
      locations = locations.filter((x) => x.ENABLED === enabledFilter);
    }

    if (companyIdFilter) {
      locations = locations.filter((x) => x.COMPANY_ID == companyIdFilter);
    }

    if (search && search.length > 0) {
      locations = new Fuse(locations, {
        keys: ["NAME", "ID"],
        useExtendedSearch: true,
        threshold: 0.5,
      })
        .search(search)
        .map(({item}) => item);
    }

    return locations;
  };

  renderCompanySelector = () => {
    let {companyIdFilter} = this.state;
    let {companies} = this.props.shop;

    companies = companies.sort((a, b) => a.NAME.localeCompare(b.NAME));

    let companyOptions = [
      {label: "All", value: "-1"},
      ...companies.map((item) => ({
        label: item.NAME,
        value: item.ID,
      })),
    ];

    return (
      <div className="m-t-25">
        <SelectFormGroup
          label="Company"
          name="company"
          data={companyOptions}
          value={companyIdFilter}
          onChange={(e) => {
            this.setState({
              companyIdFilter: e.target.value !== "-1" ? e.target.value : null,
            });
          }}
        />
      </div>
    );
  };

  render = () => {
    let {companies} = this.props.shop;

    return (
      <>
        <Breadcrumb parent="Dripos Ecosystem" title="Locations" />

        <Container fluid={true}>
          <SideFilter
            filters={[
              {label: "Enabled Locations", value: 1, icon: <UserCheck />},
              {
                label: "Disabled Locations",
                value: 0,
                icon: <UserX />,
              },
            ]}
            subFilters={companies.map((item) => ({
              label: item.NAME,
              value: item.ID,
            }))}
            defaultFilter={1}
            createLabel="Create Location"
            onCreate={() => this.props.history.push("/location/new")}
            searchPlaceholder={"Search by Name or ID"}
            renderAfter={this.renderCompanySelector()}
          >
            {({filter, search}) => {
              let locationData = this.filterLocations(filter, search);

              return (
                <DataTable
                  onRowClicked={({ID: id}) =>
                    this.props.history.push("/location/" + id)
                  }
                  columns={[
                    {
                      name: "ID",
                      selector: "ID",
                      width: "100px",
                    },
                    {
                      name: "Location Name",
                      selector: "NAME",
                    },
                    {
                      name: "Company Name",
                      selector: "COMPANY_NAME",
                      format: (row) => {
                        let currentCompany = companies.find(
                          (x) => x.ID === row.COMPANY_ID
                        );
                        return currentCompany?.NAME ?? "-";
                      },
                    },
                  ]}
                  data={locationData}
                  highlightOnHover
                  pointerOnHover
                  pagination
                  noHeader
                />
              );
            }}
          </SideFilter>
        </Container>
      </>
    );
  };
}

export default setupReduxConnection(["shop"])(LocationsPage);
