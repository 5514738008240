import React, {Component} from "react";
import {setupReduxConnection} from "../../redux";
import {Stage, Layer, Rect, Text} from "react-konva";
import Konva from "konva";
import {getShortURL} from "../../utils/request";
import io from 'socket.io-client';

class ColoredRect extends React.Component {
  state = {
    color: "green"
  };
  handleClick = () => {
    this.setState({
      color: Konva.Util.getRandomColor()
    });
  };

  render() {
    return (
      <Rect
        x={20}
        y={20}
        width={50}
        height={50}
        fill={this.state.color}
        shadowBlur={5}
        onClick={this.handleClick}
      />
    );
  }
}


class TicketTickers extends Component {
  state = {};

  componentDidMount() {
    let socket = io.connect(getShortURL(), {
      query: "b64=1"
    });

    let token = window.localStorage["TOKEN"];
    socket.on("connect", () => {
      socket.emit("admin", {
        TOKEN: token
      });
    });

    socket.on("checkout", (data) => {
      if (!data.NAME) {
        data.NAME = null;
      }

      console.log("YO", data);
    });
  }

  render() {
    let {fetched, start, end} = this.state;

    return (
      <Stage width={window.innerWidth} height={window.innerHeight}>
        <Layer>
          <Text text="Try click on rect" />
          <ColoredRect />
        </Layer>
      </Stage>
    );
  }
}

export default setupReduxConnection(["shop"])(TicketTickers);
