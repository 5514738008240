import React, {Component} from "react";
import {setupReduxConnection} from "../../redux";

class ScreenLoader extends Component {
  render() {
    let {isLoading} = this.props.global;

    return (
      <div className={`loader-wrapper ${isLoading ? "" : "loderhide"}`}>
        <div className="loader-index">
          <span />
        </div>
        <svg>
          <defs />
          <filter id="goo">
            <fegaussianblur
              in="SourceGraphic"
              stdDeviation="11"
              result="blur"
            />
            <fecolormatrix
              in="blur"
              values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9"
              result="goo"
            />
          </filter>
        </svg>
      </div>
    );
  }
}

export default setupReduxConnection(["global"])(ScreenLoader);
