import React, {Component, Fragment} from "react";
import ScreenLoader from "../components/screen-loading/screen-loader";
import TapTop from "../components/tap-top/tap-top";
import Header from "./header/header";
import {request} from "../utils/request";
import {getStore, setupReduxConnection} from "../redux";
import {updateDarkMode, updateLoading, updateMounted} from "../redux/global";
import {withRouter} from "react-router-dom";
import Sidebar from "./sidebar/sidebar";
import Footer from "./footer/footer";
import Theme from "./theme/theme";
import {updateShop} from "../redux/shop";

class App extends Component {
  async componentDidMount() {
    let {dispatch} = getStore();

    let darkMode = localStorage.getItem("LAYOUT_VERSION");
    if (darkMode === "dark-only") {
      dispatch(updateDarkMode(true));
      document.body.className = "dark-only";
    } else {
      document.body.className = "light";
    }

    const {shop} = await request("admin/dashboard/valid", "GET", null).catch(({error}) => {
      this.props.history.push("/login");

      dispatch(updateLoading(false));

      return Promise.reject("LOGIN_INVALID");
    });

    dispatch(updateLoading(false));
    dispatch(updateMounted(true));
    dispatch(updateShop(shop));
  }

  render() {
    let {isMounted} = this.props.global;
    let {children} = this.props;

    return (
      <Fragment>
        <ScreenLoader />
        <TapTop />

        {isMounted ? (
          <div className="page-wrapper compact-wrapper" id="pageWrapper">
            <Header />

            <div className="page-body-wrapper sidebar-icon">
              <Sidebar />
              <div className="page-body">{children}</div>
              <Footer />
            </div>

            <Theme />
          </div>
        ) : (
          <div />
        )}
      </Fragment>
    );
  }
}

export default setupReduxConnection(["global"])(withRouter(App));
