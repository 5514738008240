import React, {Component} from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Nav,
  NavItem,
  Row,
} from "reactstrap";
import {Home, PlusSquare} from "react-feather";
import PropTypes from "prop-types";

class SideFilter extends Component {
  state = {
    filter: this.props.defaultFilter ?? null,
    subFilter: null,
    subSubFilter: null,
    search: "",
  };

  _onChange(filter, value) {
    let updateDict = this.state;
    let {onChange} = this.props;
    updateDict[filter] = value;
    if (onChange) {
      onChange(updateDict);
    }
  }

  renderSubFilters() {
    const {subFilters, subFilterLabel, onSubFilterAdd} = this.props;
    const {subFilter} = this.state;

    if (!subFilterLabel) {
      return <div />;
    }

    return (
      <>
        <hr />

        <div className="email-app-sidebar left-bookmark">
          <Nav className="main-menu contact-options" role="tablist">
            <NavItem>
              <span className="main-title">{subFilterLabel}</span>
            </NavItem>
            <NavItem>
              <a
                href="#"
                className={subFilter === null ? "active" : ""}
                onClick={() => {
                  this._onChange("subFilter", null);
                  this.setState({subFilter: null});
                }}
              >
                <span className="title">All</span>
              </a>
            </NavItem>
            {subFilters &&
              subFilters.map(({label, value}) => (
                <NavItem>
                  <a
                    href="#"
                    className={subFilter === value ? "active" : ""}
                    onClick={() => {
                      this._onChange("subFilter", value);
                      this.setState({subFilter: value});
                    }}
                  >
                    <span className="title">{label}</span>
                  </a>
                </NavItem>
              ))}
            {onSubFilterAdd ? (
              <NavItem>
                <a
                  style={{fontWeight: "bold"}}
                  href="#"
                  onClick={onSubFilterAdd}
                >
                  <span className="title"> + Add</span>
                </a>
              </NavItem>
            ) : (
              <div />
            )}
          </Nav>
        </div>
      </>
    );
  }

  renderSubSubFilters() {
    const {subSubFilters, subSubFilterLabel = null} = this.props;
    const {subSubFilter} = this.state;

    if (subSubFilterLabel === null) {
      return <div />;
    }

    return (
      <>
        <hr />

        <div className="email-app-sidebar left-bookmark">
          <Nav className="main-menu contact-options" role="tablist">
            <NavItem>
              <span className="main-title">{subSubFilterLabel}</span>
            </NavItem>
            <NavItem>
              <a
                href="#"
                className={subSubFilter === null ? "active" : ""}
                onClick={() => {
                  this._onChange("subSubFilter", null);
                  this.setState({subSubFilter: null});
                }}
              >
                <span className="title">All</span>
              </a>
            </NavItem>
            {subSubFilters.map(({label, value}) => (
              <NavItem>
                <a
                  href="#"
                  className={subSubFilter === value ? "active" : ""}
                  onClick={() => {
                    this._onChange("subSubFilter", value);
                    this.setState({subSubFilter: value});
                  }}
                >
                  <span className="title">{label}</span>
                </a>
              </NavItem>
            ))}
          </Nav>
        </div>
      </>
    );
  }

  render() {
    const {
      filters,
      children,
      createLabel = null,
      onCreate,
      searchPlaceholder,
    } = this.props;
    const {filter, search} = this.state;

    return (
      <div className="email-wrap bookmark-wrap">
        <Row>
          {filters && (
            <Col xl="3" className="box-col-6 pr-0 file-spacing">
              <div className="file-sidebar email-left-aside">
                <Card>
                  <CardBody>
                    {this.props.renderBefore}
                    <ul>
                      <li>
                        <div
                          onClick={() => {
                            this._onChange("filter", null);
                            this.setState({filter: null});
                          }}
                          className={
                            "btn " +
                            (filter === null ? "btn-primary" : "btn-light")
                          }
                        >
                          <Home />
                          All
                        </div>
                      </li>

                      {filters.map(({value, label, icon}) => (
                        <li>
                          <div
                            onClick={() => {
                              this._onChange("filter", value);
                              this.setState({filter: value});
                            }}
                            className={
                              "btn " +
                              (filter === value ? "btn-primary" : "btn-light")
                            }
                          >
                            {icon}

                            {label}
                          </div>
                        </li>
                      ))}
                    </ul>

                    {this.renderSubFilters()}
                    {this.renderSubSubFilters()}
                    {this.props.renderAfter}
                  </CardBody>
                </Card>
              </div>
            </Col>
          )}

          <Col xl={filters ? "9" : "12"} md="12" className="box-col-12">
            <div className="file-content bookmark-tabcontent">
              <Card>
                {(!this.props.hideSearch || createLabel !== null) && (
                  <CardHeader>
                    <div className="media">
                      {!this.props.hideSearch && (
                        <Form className="form-inline">
                          <FormGroup>
                            <i className="fa fa-search mr-1" />

                            <Input
                              className="form-control-plaintext"
                              type="text"
                              value={search}
                              onChange={(e) => {
                                this._onChange("search", e.target.value);
                                this.setState({search: e.target.value});
                              }}
                              placeholder={
                                searchPlaceholder
                                  ? searchPlaceholder
                                  : "Search..."
                              }
                            />
                          </FormGroup>
                        </Form>
                      )}

                      {createLabel !== null ? (
                        <div className="media-body text-right">
                          <Form
                            className="d-inline-flex"
                            style={{alignItems: "center"}}
                          >
                            <div className="btn btn-primary" onClick={onCreate}>
                              <PlusSquare />
                              {createLabel}
                            </div>
                          </Form>
                        </div>
                      ) : (
                        <div />
                      )}
                    </div>
                  </CardHeader>
                )}

                <CardBody>{children(this.state)}</CardBody>
              </Card>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

SideFilter.defaultProps = {
  onCreate: () => console.log("Create not implemented"),
};

SideFilter.propTypes = {
  filters: PropTypes.array,

  onCreate: PropTypes.func,
  createLabel: PropTypes.string,

  subFilters: PropTypes.array,
  onSubFilterAdd: PropTypes.func,
  subFilterLabel: PropTypes.string,

  subSubFilters: PropTypes.array,
  subSubFilterLabel: PropTypes.string,

  searchPlaceholder: PropTypes.string,

  // value of the filter that you want selected by default
  defaultFilter: PropTypes.any,

  // React Node to Render Before/After the Filter Block
  renderBefore: PropTypes.any,
  renderAfter: PropTypes.any,

  // If true, hide the search bar
  hideSearch: PropTypes.bool,
};

export default SideFilter;
