import React, {Component} from "react";
import {Form, Row} from "reactstrap";
import {Link} from "react-router-dom";
import {X} from "react-feather";
import RightHeader from "./right-header";
import LeftHeader from "./left-header";

class Header extends Component {
  state = {search: ""};

  render() {
    let {search} = this.state;

    return (
      <div className="page-header">
        <Row className="header-wrapper m-0">
          <Form className="form-inline search-full" action="#" method="get">
            <div className="form-group w-100">
              <div className="Typeahead Typeahead--twitterUsers">
                <div className="u-posRelative">
                  <input
                    className="Typeahead-input form-control-plaintext w-100"
                    id="demo-input"
                    type="search"
                    placeholder="Search Cuba .."
                    defaultValue={search}
                    // onChange={(e) => handleSearchKeyword(e.target.value)}
                  />
                  <div
                    className="spinner-border Typeahead-spinner"
                    role="status"
                  >
                    <span className="sr-only">Loading</span>
                  </div>
                  <X
                    className="close-search"
                    onClick={() =>
                      document
                        .querySelector(".search-full")
                        .classList.remove("open")
                    }
                  />
                </div>
                <div
                  className="Typeahead-menu custom-scrollbar"
                  id="search-outer"
                >
                  {search
                    ? search.map((data, index) => {
                        return (
                          <div className="ProfileCard u-cf" key={index}>
                            <div className="ProfileCard-avatar">
                              <data.icon />
                            </div>
                            <div className="ProfileCard-details">
                              <div className="ProfileCard-realName">
                                <Link
                                  to={data.path}
                                  className="realname"
                                  // onClick={removeFix}
                                >
                                  {data.title}
                                </Link>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    : ""}
                </div>
                <div className="Typeahead-menu empty-menu">
                  <div className="tt-dataset tt-dataset-0">
                    <div className="EmptyMessage">
                      {"Opps!! There are no result found."}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>

          <LeftHeader />
          <RightHeader />
        </Row>
      </div>
    );
  }
}

export default Header;
