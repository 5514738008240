import React, {Component} from "react";
import {ChevronsUp} from "react-feather";

class TapTop extends Component {
  state = {display: "block"};

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll.bind(this));

    this.handleScroll();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll.bind(this));
  }

  handleScroll() {
    if (window.scrollY > 600) {
      this.setState({display: "block"});
    } else {
      this.setState({display: "none"});
    }
  }

  executeScroll() {
    window.scrollTo({top: 0, left: 0, behavior: "smooth"});
  }

  render() {
    let {display} = this.state;

    return (
      <div className="tap-top" style={{display}}>
        <ChevronsUp onClick={this.executeScroll.bind(this)} />
      </div>
    );
  }
}

export default TapTop;
