import React, {Component} from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import {Provider} from "react-redux";
import {getStore, setupStore} from "./redux";
import App from "./layouts/app";
import {BrowserRouter, Route, Switch, Redirect} from "react-router-dom";
import {CSSTransition, TransitionGroup} from "react-transition-group";
import {routes} from "./routing/routes";
import Login from "./pages/entry/login-page";
import NotFoundPage from "./pages/misc/not-found-page";
import TicketTickers from "./pages/tickers/ticket-tickers";

class Root extends Component {
  state = {isMounted: false};

  componentDidMount() {
    let {subscribe, getState} = getStore();

    subscribe(() => {
      let {isMounted} = getState().global;

      if (isMounted !== this.state.isMounted) {
        this.setState({isMounted});
      }
    });
  }

  render() {
    let {isMounted} = this.state;

    return (
      <>
        <Provider store={setupStore()}>
          <BrowserRouter>
            <Switch>
              <Route
                path={`${process.env.PUBLIC_URL}/login`}
                component={Login}
              />

              <Route
                path={`${process.env.PUBLIC_URL}/ticker/tickets`}
                component={TicketTickers}
              />

              <Route
                path={`${process.env.PUBLIC_URL}/*`}
                component={() => (
                  <App>
                    {isMounted ? (
                      <TransitionGroup>
                        <Switch>
                          <Route
                            exact
                            path={`${process.env.PUBLIC_URL}/`}
                            render={() => {
                              return (
                                <Redirect
                                  to={`${process.env.PUBLIC_URL}/home`}
                                />
                              );
                            }}
                          />

                          {routes.map(({path, component: Component}) => (
                            <Route
                              key={path}
                              exact
                              path={`${process.env.PUBLIC_URL}${path}`}
                            >
                              {({match, ...props}) => (
                                <CSSTransition
                                  in={match != null}
                                  timeout={100}
                                  unmountOnExit
                                >
                                  <div>
                                    <Component match={match} {...props} />
                                  </div>
                                </CSSTransition>
                              )}
                            </Route>
                          ))}

                          <Route path={`${process.env.PUBLIC_URL}/*`} component={NotFoundPage}/>
                        </Switch>
                      </TransitionGroup>
                    ) : (
                      <div />
                    )}
                  </App>
                )}
              />
            </Switch>
          </BrowserRouter>
        </Provider>
      </>
    );
  }
}

ReactDOM.render(<Root />, document.getElementById("root"));
