import React, {Component} from "react";
import {
  LogIn,
  Minimize,
} from "react-feather";
import {setupReduxConnection} from "../../redux";
import {getObjectImage} from "../../utils/asset-helper";
import {withRouter} from "react-router-dom";

class RightHeader extends Component {
  state = {notification: false, search: false, chat: false};

  logout() {
    window.localStorage.removeItem("TOKEN");

    window.location.reload();
  }

  goFull() {
    if (
      document.fullScreenElement ||
      (!document.mozFullScreen && !document.webkitIsFullScreen)
    ) {
      if (document.documentElement.requestFullScreen) {
        document.documentElement.requestFullScreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullScreen) {
        document.documentElement.webkitRequestFullScreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  toggleDarkMode() {
    let {isDarkMode} = this.props.global;

    if (isDarkMode) {
      document.body.className = "light";
      localStorage.setItem("LAYOUT_VERSION", "light");
    } else {
      document.body.className = "dark-only";
      localStorage.setItem("LAYOUT_VERSION", "dark-only");
    }

    this.props.updateDarkMode(!isDarkMode);
  }

  search() {
    let {search} = this.state;

    if (search) {
      document.querySelector(".search-full").classList.remove("open");
    } else {
      document.querySelector(".search-full").classList.add("open");
    }

    this.setState({
      search: !search,
    });
  }

  render() {
    let {isDarkMode} = this.props.global;

    return (
      <div className="nav-right col-8 pull-right right-header p-0">
        <ul className="nav-menus">
          <li>
            <div className="mode" onClick={() => this.toggleDarkMode()}>
              <i
                className={`fa ${isDarkMode ? "fa-lightbulb" : "fa-moon"}`}
              ></i>
            </div>
          </li>
          <li className="maximize">
            <a
              className="text-dark"
              href="#javascript"
              onClick={this.goFull.bind(this)}
            >
              <Minimize />
            </a>
          </li>
          <li className="profile-nav onhover-dropdown p-0">
            <div className="media profile-media">
              <div className="media-body">
                <span>Admin Dashboard</span>
              </div>
            </div>
            <ul className="profile-dropdown onhover-show-div">
              <li onClick={this.logout.bind(this)}>
                <LogIn />
                <span>Log out</span>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    );
  }
}

export default setupReduxConnection(["global"])(
  withRouter(RightHeader)
);
