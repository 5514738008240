import React, {Component} from "react";
import {Formik} from "formik";
import * as Yup from "yup";
import {
  Button,
  Col,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import InputFormGroup from "../../components/form-group/input-form-group";
import {request} from "../../utils/request";
import {sweetAlertFailure, sweetAlertSuccess} from "../../utils/sweet-alert";
import PropTypes from "prop-types";

class PartnerSettingsModal extends Component {
  state = {open: false, initialKey: null, initialValue: null};

  open(initialKey, initialValue) {
    this.setState({open: true, initialKey, initialValue});
  }

  handleUpsertPartnerSetting = async ({key, value}) => {
    let {locationId, onUpdate} = this.props;

    if (value.toLowerCase() === "true") {
      value = "1";
    }

    if (value.toLowerCase() === "false") {
      value = "0";
    }

    let partnerSettingPayload = {
      KEY: key,
      VALUE: value,
    };

    await request(
      `admin/dashboard/location/${locationId}/partner-settings`,
      "PUT",
      partnerSettingPayload
    )
      .then(async () => {
        this.setState({open: false});
        onUpdate && onUpdate();
        await sweetAlertSuccess("Partner Setting Updated");
      })
      .catch(async () => {
        await sweetAlertFailure(
          "Partner Setting Update Failed",
          "Please refresh the page and try again."
        );
      });
  };

  render() {
    let {open, initialKey, initialValue} = this.state;

    return (
      <Modal isOpen={open} toggle={() => this.setState({open: !open})} centered>
        <Formik
          onSubmit={this.handleUpsertPartnerSetting}
          validationSchema={Yup.object().shape({
            key: Yup.string().required("KEY is required."),
            value: Yup.string().required("VALUE is required"),
          })}
          initialValues={{
            key: initialKey,
            value: initialValue,
          }}
        >
          {(formikOptions) => {
            let {handleSubmit} = formikOptions;

            return (
              <Form onSubmit={handleSubmit}>
                <ModalHeader toggle={() => this.setState({open: !open})}>
                  Update Partner Setting
                </ModalHeader>

                <ModalBody>
                  <Row>
                    <Col md="6">
                      <InputFormGroup
                        label="Key"
                        name="key"
                        placeholder="REWARDS_ENABLED"
                        options={formikOptions}
                        disabled={true}
                      />
                    </Col>

                    <Col md="6">
                      <InputFormGroup
                        label="Value"
                        name="value"
                        placeholder="1 = true, 0 = false"
                        options={formikOptions}
                      />
                    </Col>
                  </Row>
                </ModalBody>

                <ModalFooter>
                  <Button
                    type="button"
                    color="secondary"
                    onClick={() => this.setState({open: false})}
                  >
                    Cancel
                  </Button>

                  <Button color="primary" type="submit">
                    Update
                  </Button>
                </ModalFooter>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

PartnerSettingsModal.propTypes = {
  locationId: PropTypes.number.isRequired,
  onUpdate: PropTypes.func.isRequired, // function to call after update is made
};

export default PartnerSettingsModal;
