import React, {Component} from "react";
import {FormGroup, Input, Label} from "reactstrap";
import PropTypes from "prop-types";

class ToggleFormGroup extends Component {
  render() {
    let {label, name, value, onChange, options, disabled = false, error} = this.props;

    return (
      <FormGroup>
        <Label className="form-label">{label}</Label>

        <Input
          value={options ? options.values[name] : value}
          type="select"
          name={name}
          disabled={disabled}
          className="form-control"
          onChange={(e) => {
            return options
              ? options.setFieldValue(name, e.target.value)
              : onChange({[name]: e.target.value});
          }}
        >
          <option value="1">Yes</option>
          <option value="0">No</option>
        </Input>

        <span style={{color: "red"}}>
          {options ? options.touched[name] && options.errors[name] : error}
        </span>
      </FormGroup>
    );
  }
}

ToggleFormGroup.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,

  value: PropTypes.any,
  options: PropTypes.object,
  onChange: PropTypes.func,

  disabled: PropTypes.bool,
  error: PropTypes.string,
};

export default ToggleFormGroup;
