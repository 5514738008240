import React, {Component} from "react";
import {FormGroup, Input, Label} from "reactstrap";
import PropTypes from "prop-types";
import InputMask from "react-input-mask";

class PhoneFormGroup extends Component {
  render() {
    let {
      label,
      error,
      name,
      value,
      onChange,
      onBlur,
      placeholder,
      mask,
      disabled = false,
      options = null,
    } = this.props;

    return (
      <FormGroup>
        <Label className="col-form-label" for="recipient-name">
          {label}
        </Label>

        <Input
          name={name}
          disabled={disabled}
          onChange={options ? options.handleChange : onChange}
          onBlur={options ? options.handleBlur : onBlur}
          value={options ? options.values[name] : value}
          placeholder={placeholder}
          className="form-control"
          type="tel"
          mask={mask ? mask : "999 999 9999"}
          maskChar=" "
          tag={InputMask}
        />

        <span style={{color: "red"}}>
          {options ? options.touched[name] && options.errors[name] : error}
        </span>
      </FormGroup>
    );
  }
}

PhoneFormGroup.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,

  options: PropTypes.object,
  error: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  mask: PropTypes.string,

  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};

export default PhoneFormGroup;
