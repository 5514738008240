import SweetAlert from "sweetalert2";

export async function sweetAlertSuccess(title, text){
  await SweetAlert.fire({
    title: title,
    text: text,
    icon: "success",
  });
}

export async function sweetAlertFailure(title, text){
  await SweetAlert.fire({
    title: title,
    text: text,
    icon: "warning",
  });
}

/*
    Fires a confirmation modal.
    Returns: true if confirmed, false if denied
*/
export async function sweetConfirm(title, text, icon = "warning", confirmButtonText = "Ok") {
  let result = await SweetAlert.fire({
    title: title,
    text: text,
    icon: icon,
    showCancelButton: true,
    confirmButtonText: confirmButtonText,
    cancelButtonText: "Cancel",
    reverseButtons: true,
  })

  if (result.value) {
    return true
  } else {
    return false
  }
}