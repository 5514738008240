import React, {Component} from "react";
import Breadcrumb from "../../layouts/breadcrumb/breadcrumb";
import {Button, Card, CardBody, CardHeader, Col, Container, Row} from "reactstrap";
import InputFormGroup from "../../components/form-group/input-form-group";
import {request} from "../../utils/request";
import DataTable from "react-data-table-component";
import {getFormattedPhone} from "../../utils/phone";

class AccountsPage extends Component {
  state = {
    accounts: [],
    totalCount: 0,
    accountsSearch: "",
    isLoading: false,
    pagination: {
      perPage: 10,
      page: 1,
    },
  };

  componentDidMount() {
    this.handleFetchAccounts();
  }

  handleFetchAccounts = async (page = 1, perPage = 10) => {
    let {accountsSearch} = this.state;

    this.setState({isLoading: true});

    let {
      pagination
    } = this.state;

    if (page) {
      pagination.page = page;
    }

    if (perPage) {
      pagination.perPage = perPage;
    }

    let accounts = await request("admin/accounts/all", "GET", {
      SEARCH: accountsSearch,
      PAGE: pagination.page,
      PER_PAGE: pagination.perPage,
    });

    this.setState({
      accounts: accounts.ACCOUNTS,
      totalCount: accounts.TOTAL_COUNT,
      isLoading: false,
      pagination,
    });
  };

  render = () => {
    let {accounts, totalCount, pagination, isLoading} = this.state;
    return (
      <>
        <Breadcrumb parent="Admin" title="Accounts"/>

        <Container fluid={true}>
          <Card>
            <CardHeader>
              <Row>
                <Col xl={3}>
                  <InputFormGroup
                    label={"Search by ID, Name, Phone, or Email"}
                    name={"accountsSearch"}
                    onChange={(e) => this.setState({accountsSearch: e.target.value})}
                    placeholder={"Search..."}
                  />

                  <Button color="primary" onClick={this.handleFetchAccounts}>Search</Button>
                </Col>
              </Row>
            </CardHeader>

            <CardBody>
              <DataTable
                onRowClicked={({ID}) =>
                  this.props.history.push("/accounts/" + ID)
                }
                columns={ACCOUNT_COLUMNS}
                data={accounts}
                highlightOnHover
                pointerOnHover
                pagination
                paginationServer
                paginationTotalRows={totalCount}
                paginationPerPage={pagination.per_page}
                onChangePage={(page) => {
                  this.handleFetchAccounts(page);
                }}
                onChangeRowsPerPage={(per_page, page) => {
                  this.handleFetchAccounts(page, per_page);
                }}
                noHeader
                progressPending={isLoading}
              />
            </CardBody>
          </Card>
        </Container>
      </>
    );
  };
}

export default (AccountsPage);

const ACCOUNT_COLUMNS = [
  {
    name: "ID",
    selector: "ID",
  },
  {
    name: "Name",
    selector: "FULL_NAME",
  },
  {
    name: "Phone",
    selector: "PHONE",
    format: (row) => getFormattedPhone(row.PHONE)
  },
  {
    name: "EMAIL",
    selector: "EMAIL",
  }
];
