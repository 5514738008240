import React, {Component} from "react";
import {Col, Container, Row} from "reactstrap";

class Footer extends Component {
  render() {
    return (
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col md="12" className="footer-copyright text-center">
              <p className="mb-0">
                {"Copyright 2020 © Dripos by Frostbyte Technologies"}
              </p>
            </Col>
          </Row>
        </Container>
      </footer>
    );
  }
}

export default Footer;
