import React, {Component} from "react";
import {Card, CardBody, Col, Row} from "reactstrap";
import PropTypes from "prop-types";

class LogoValueCard extends Component {
  render() {
    let {logo, title, label, value} = this.props;

    return (
      <Card className="social-widget-card">
        <CardBody>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100px",
              height: "100px",
              margin: "0 auto",
            }}
          >
            {logo}
          </div>
          <h5 className="b-b-light">{title}</h5>
          <Row>
            <Col className="text-center">
              <span>{label}</span>
              <h4 className="mb-0">{value}</h4>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }
}

LogoValueCard.propTypes = {
  logo: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.any.isRequired,
};

export default LogoValueCard;