import React, {Component} from "react";
import {Formik} from "formik";
import * as Yup from "yup";
import {
  Button,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import {sweetConfirm} from "../../utils/sweet-alert";
import SelectFormGroup from "../../components/form-group/select-form-group";
import {request} from "../../utils/request";
import SweetAlert from "sweetalert2";

class MenuModal extends Component {
  state = {open: false, creating: false, serverLocation: null};

  open(serverLocation) {
    this.setState({open: true, creating: false, serverLocation});
  }

  async pullMenu({location}) {
    const {creating} = this.state;

    if (creating) {
      return;
    }

    const isNoGood = await sweetConfirm(
      "Pull Menu",
      "ARE YOU SURE YOU WANT TO PULL MENU. ONLY RUN THIS ONCE AND IT CANNOT BE UNDONE."
    );

    if (!isNoGood) {
      return;
    }

    this.setState({creating: true});

    const {serverLocation} = this.state;

    this.setState({open: false});

    await request("admin/dashboard/location/sync", "POST", {
      SECRET: "1231231236969696969",
      LOCATION_ID: serverLocation.ID,
      PULL_LOCATION_ID: parseInt(location),
    });

    SweetAlert.fire({
      title: "Menu Pull in Progress",
      text: "This is gonna take some time, wait for page to reload before doing anything else",
      icon: "success",
    });

    setTimeout(() => {
      window.location.reload();
    }, 1000 * 60);

    // await request("employees", "POST", {});
  }

  render() {
    const {open} = this.state;
    const {locations} = this.props.shop;

    return (
      <Modal isOpen={open} toggle={() => this.setState({open: !open})} centered>
        <Formik
          onSubmit={this.pullMenu.bind(this)}
          validationSchema={Yup.object().shape({
            location: Yup.string().required("Select a location"),
          })}
          initialValues={{
            location: "",
          }}
        >
          {(formikOptions) => {
            let {handleSubmit, setFieldValue} = formikOptions;

            return (
              <Form onSubmit={handleSubmit}>
                <ModalHeader toggle={() => this.setState({open: !open})}>
                  Pull Menu from Other Location
                </ModalHeader>

                <ModalBody>
                  <SelectFormGroup
                    options={formikOptions}
                    label="Location"
                    name="location"
                    data={locations.map((item) => ({
                      label: item.NAME,
                      value: item.ID,
                    }))}
                  />
                </ModalBody>

                <ModalFooter>
                  <Button
                    type="button"
                    color="secondary"
                    onClick={() => this.setState({open: false})}
                  >
                    Cancel
                  </Button>

                  <Button color="primary" type="submit">
                    plz work plz omg
                  </Button>
                </ModalFooter>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default MenuModal;
