import React, {Component} from "react";
import Breadcrumb from "../../layouts/breadcrumb/breadcrumb";
import {setupReduxConnection} from "../../redux";
import Fuse from "fuse.js";
import {request} from "../../utils/request";
import {Container} from "reactstrap";
import {DollarSign, UserCheck, UserX} from "react-feather";
import SideFilter from "../../components/side-filter/side-filter";
import DataTable from "react-data-table-component";
import {
  TICKET_PLATFORMS,
  TICKET_TRANSACTION_TYPES,
} from "../../utils/constants";
import {parseIdDict, toDollars} from "../../utils/util";
import moment from "moment";

/*
    TODO TODO TODO - THIS IS THE OLD TICKETS PAGE. UPDATE TO THE NEW TICKETS PAGE SETUP
 */
class TicketsPage extends Component {
  state = {
    filter: null,
    category: null,
    search: "",
    grid: true,
    orders: {
      data: [],
      totalCount: 0,
    },
    filters: {},
    pagination: {
      per_page: 10,
      page: 1,
    },
  };

  componentDidMount() {
    this._handleFetchOrders({}, 1);
  }

  _getType(ticket) {
    if (ticket.ORDER_TYPE_ID !== null) {
      return ticket.ORDER_TYPE_NAME
    }

    switch (ticket.TYPE) {
      default:
        return "For Here";
      case 1:
        return "To Go";
      case 2:
        return "Delivery";
      case 3:
        return "Pickup";
      case 5:
        return "In Store";
    }
  }

  async _handleFetchOrders(filters, page, per_page=null) {
    let {search, filter, subFilter, subSubFilter} = filters;
    let {pagination} = this.state;


    if (page) {
      pagination.page = page;
    }

    if (per_page) {
      pagination.per_page = per_page
    }

    let orders = await request(
      "partner/orders/list",
      "GET",
      {
        page: pagination.page,
        perPage: pagination.per_page,
        platform: filter,
        paymentType: subFilter,
        orderType: (subSubFilter && String(subSubFilter).includes("_")) ? null : subSubFilter,
        orderTypeId: (subSubFilter && String(subSubFilter).includes("_")) ? subSubFilter.replace('_', '') : null,
        search
      }
    );

    this.setState({orders, pagination});
  }

  render() {
    let {orders, pagination} = this.state;

    return (
      <>
        <Breadcrumb parent="Admin" title="Tickets" />

        <Container fluid={true}>
          <SideFilter
            onChange={this._handleFetchOrders.bind(this)}
            subFilterLabel="Payment Types"
            subSubFilterLabel="Order Types"
            filters={[
              {label: "Point of Sale", value: 1, icon: <UserCheck />},
              {label: "Mobile App", value: 0, icon: <DollarSign />},
              {label: "Web Orders", value: 3, icon: <UserX />},
              {label: "Kiosk", value: 2, icon: <UserX />},
            ]}
            subFilters={[
              {label: "Mobile", value: 0},
              {label: "Card", value: 1},
              {label: "Cash", value: 2},
              {label: "Tab", value: 3},
              {label: "Gift Card", value: 4},
              {label: "Other", value: 5},
              {label: "Rewards", value: 7},
              {label: "Split", value: 8},
              {label: "Drip Balance", value: 9},
            ]}
            subSubFilters={[
              {label: "For Here", value: 0},
              {label: "To Go", value: 1},
              {label: "Delivery", value: 2},
              {label: "Contactless Pickup", value: 3},
              {label: "In Store", value: 5},
            ]}
          >
            {(options) => {
              return (
                <DataTable
                  onRowClicked={({id}) =>
                    this.props.history.push("/order/" + id)
                  }
                  columns={[
                    {
                      name: "Name",
                      selector: "name",
                    },
                    {
                      name: "Platform",
                      selector: "platform",
                    },
                    {
                      name: "Payment Type",
                      selector: "payment",
                    },
                    {
                      name: "Order Type",
                      selector: "type",
                    },
                    {
                      name: "Total",
                      selector: "total",
                      sortable: true,
                      sortFunction: (a, b) => {
                        return a.item.TOTAL - b.item.TOTAL;
                      },
                    },
                    {
                      name: "Date Sent",
                      selector: "date",
                      sortable: true,
                      sortFunction: (a, b) => {
                        return a.item.DATE_START - b.item.DATE_START;
                      },
                      width: "auto"
                    },
                  ]}
                  data={orders.data.map((item, i) => ({
                    item,
                    id: item.ID,
                    name: item.NAME,
                    platform: TICKET_PLATFORMS[item.PLATFORM],
                    total: "$" + toDollars(item.TOTAL),
                    type: this._getType(item),
                    date: moment(
                      item.DATE_SENT
                    ).format("ddd MMM Do h:mma"),
                  }))}
                  defaultSortAsc={false}
                  defaultSortField="id"
                  highlightOnHover
                  pointerOnHover
                  pagination
                  paginationServer
                  paginationTotalRows={orders.totalCount}
                  paginationPerPage={pagination.per_page}
                  onChangePage={(page) => {
                    this._handleFetchOrders(options, page);
                  }}
                  onChangeRowsPerPage={(per_page, page) => {
                    this._handleFetchOrders(options, page, per_page);
                  }}
                  noHeader
                />
              );
            }}
          </SideFilter>
        </Container>
      </>
    );
  }
}

export default setupReduxConnection(["tickets", "menu"])(TicketsPage);
