import {asyncDelay} from "../utils/util";
import {request} from "../utils/request";

const defaultState = {
  isCompleted: false,
  isLoading: false,
  unique: null,
  error: null,
  phone: "",
  token: "",
};

const UPDATE_PHONE = "UPDATE_PHONE";
const UPDATE_TOKEN = "UPDATE_TOKEN";
const UPDATE_LOADING = "UPDATE_LOADING";
const UPDATE_ERROR = "UPDATE_ERROR";
const UPDATE_UNIQUE = "UPDATE_UNIQUE";
const UPDATE_COMPLETED = "UPDATE_COMPLETED";

export function sendEntryPhone() {
  return async (dispatch, getState) => {
    let {phone} = getState().entry;

    dispatch(updateEntryLoading(true));

    let cleanPhone = "1" + phone.replaceAll(" ", ""), start = Date.now();
    let token = await request('login/initiate', 'POST', {PHONE: cleanPhone}).catch(() => {
      dispatch(updateEntryError("YUP IT ERROR"));

      return Promise.reject("LOGIN_ERROR");
    });

    await asyncDelay(start);

    dispatch(updateEntryUnique(token));
    dispatch(updateEntryLoading(false));
  };
}

export function sendEntryToken() {
  return async (dispatch, getState) => {
    let {unique, token} = getState().entry;

    dispatch(updateEntryLoading(true));

    let start = Date.now();
    let payload = await request('login/admin/complete', 'POST', {
      UNIQUE: unique, TOKEN: token.replaceAll(" ", ""), CLIENT: {
        NAME: "Admin",
        INFO: "Website",
        TYPE: 2
      }
    }).catch(() => {

      return Promise.reject("TOKEN_INVALID");
    }).finally(async () => {
      await asyncDelay(start);

      dispatch(updateEntryLoading(false));
    });

    dispatch(updateEntryError("YUP IT ERROR"));
    dispatch(updateEntryCompleted(true));

    let secondStart = Date.now();
    localStorage.setItem("TOKEN", payload);

    await asyncDelay(secondStart, 1000);
  };
}

export function updateEntryToken(credentials) {
  return {
    type: UPDATE_TOKEN,
    payload: credentials,
  };
}

export function updateEntryPhone(credentials) {
  return {
    type: UPDATE_PHONE,
    payload: credentials,
  };
}

export function updateEntryLoading(credentials) {
  return {
    type: UPDATE_LOADING,
    payload: credentials,
  };
}

export function updateEntryError(credentials) {
  return {
    type: UPDATE_ERROR,
    payload: credentials,
  };
}

export function updateEntryUnique(credentials) {
  return {
    type: UPDATE_UNIQUE,
    payload: credentials,
  };
}

export function updateEntryCompleted(credentials) {
  return {
    type: UPDATE_COMPLETED,
    payload: credentials,
  };
}

export const entryReducer = (state = defaultState, action) => {
  let {type, payload} = action;

  switch (type) {
    default:
      return state;
    case UPDATE_PHONE:
      return {...state, phone: payload};
    case UPDATE_LOADING:
      return {...state, isLoading: payload};
    case UPDATE_COMPLETED:
      return {...state, isCompleted: payload};
    case UPDATE_ERROR:
      return {...state, error: payload};
    case UPDATE_UNIQUE:
      return {...state, unique: payload};
    case UPDATE_TOKEN:
      return {...state, token: payload};
  }
};
