import React, {Component} from "react";
import {Button, Form, FormGroup, Input, Label} from "reactstrap";
import {withRouter} from "react-router-dom"
import InputMask from "react-input-mask";

class LoginTokenForm extends Component {
  state = {error: null};

  sendPhone(e) {
    e.preventDefault();

    this.props.sendEntryToken().then(() => {
      this.props.history.push("/");

      window.location.reload();
    });
  }

  render() {
    let {token, error} = this.props.entry;

    return (
      <Form className="theme-form" onSubmit={this.sendPhone.bind(this)}>
        <h4>Login</h4>
        <FormGroup>
          <Label className="col-form-label">Enter Token from Phone</Label>

          <Input
            className="form-control"
            placeholder="123 456"
            value={token}
            onChange={(e) => this.props.updateEntryToken(e.target.value)}
            type="text"
            mask="999 999"
            maskChar=" "
            tag={InputMask}
            required
          />
          {error ? (
            <div style={{color: "red", paddingTop: 5}}>{error}</div>
          ) : (
            <div />
          )}
        </FormGroup>
        <FormGroup className="mb-0">
          <Button color="primary" className="btn-block" type="submit">
            Send Token
          </Button>
        </FormGroup>
      </Form>
    );
  }
}

export default withRouter(LoginTokenForm);
