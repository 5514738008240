import React, {Component} from "react";
import {Link} from "react-router-dom";
import {Col} from "reactstrap";
import {Sliders} from "react-feather";

class LeftHeader extends Component {
  state = {showOverseer: false, showSidebar: false, width: 0};

  componentDidMount() {
    window.addEventListener("resize", this.updateSize);
    document.addEventListener("click", this.onDocumentClick);

    this.updateSize();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateSize);
    document.removeEventListener("click", this.onDocumentClick);
  }

  onDocumentClick = (event) => {
    let ignoreClick_On_Out_side_Element = document.getElementById(
      "out_side_click"
    );

    let ignoreClick_On_Main_Nav_Element = document.getElementById(
      "sidebar-menu"
    );

    let isClickOutSideElement = ignoreClick_On_Out_side_Element.contains(
      event.target
    );

    let isClickMainNavElement = ignoreClick_On_Main_Nav_Element.contains(
      event.target
    );

    if (
      window.innerWidth <= 991 &&
      !isClickOutSideElement &&
      !isClickMainNavElement
    ) {
      document.querySelector(".page-header").className =
        "page-header close_icon";
      document.querySelector(".sidebar-wrapper").className =
        "sidebar-wrapper close_icon ";
    }
  };

  updateSize = () => {
    this.setState({width: window.innerWidth});
  };

  selectLocation(item) {
    console.log("WINDOW");
    window.localStorage.setItem("CURRENT_LOCATION", item.ID);
    window.location.reload();
  }

  responsive_openCloseSidebar = () => {
    if (window.width <= 991) {
      document.querySelector(".page-header").className = "page-header";
      document.querySelector(".sidebar-wrapper").className = "sidebar-wrapper ";
    } else {
      document.querySelector(".page-header").className = "page-header ";
      document.querySelector(".sidebar-wrapper").className = "sidebar-wrapper ";
    }
  };

  render() {
    return (
      <>
        <div className="header-logo-wrapper" id="out_side_click">
          <div className="logo-wrapper">
            <Link to={`${process.env.PUBLIC_URL}/home`}>
              <img
                className="img-fluid for-light"
                src={require("../../assets/images/logo/DriposLogo.png")}
                alt=""
              />
              <img
                className="img-fluid for-dark"
                src={require("../../assets/images/logo/DriposLogoDark.png")}
                alt=""
              />
            </Link>
          </div>

          <div
            className="toggle-sidebar"
            onClick={() => this.responsive_openCloseSidebar()}
            style={
              window.innerWidth <= 991 ? {display: "block"} : {display: "none"}
            }
          >
            <Sliders
              className="status_toggle middle sidebar-toggle"
              id="sidebar-toggle"
            />
          </div>
        </div>

        <Col className="left-header horizontal-wrapper pl-0"></Col>
      </>
    );
  }
}

export default LeftHeader;
