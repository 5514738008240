import React, {Component, Fragment} from "react";
import Breadcrumbs from "../../layouts/breadcrumb/breadcrumb";
import {request} from "../../utils/request";
import {setupReduxConnection} from "../../redux";
import {Formik} from "formik";
import {Link} from "react-router-dom";
import * as Yup from "yup";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Media,
  Row,
} from "reactstrap";

class NewLocationPage extends Component {
  async createLocation({name, company, address, city, state, zipcode, tax, longitude, latitude}) {
    const serverLocation = await request("admin/dashboard/location", "POST", {
      NAME: name,
      TAX_RATE: tax,
      COMPANY_ID: company,
      ADDRESS: address,
      CITY: city,
      STATE: state,
      ZIPCODE: zipcode,
      LATITUDE: latitude,
      LONGITUDE: longitude,
    });

    this.props.addLocation(serverLocation);
    this.props.history.push("/location/" + serverLocation.ID);
  }

  render() {
    const {companies} = this.props.shop;

    return (
      <Fragment>
        <Breadcrumbs
          parentLink="/locations"
          parent="Locations"
          title="Create Location"
        />
        <Container fluid={true}>
          <Row>
            <Col sm="12">
              <Card>
                <CardBody>
                  <Formik
                    onSubmit={this.createLocation.bind(this)}
                    initialValues={{
                      name: "",
                      company: "",
                      address: "",
                      zipcode: "",
                      city: "",
                      state: "",
                      tax: "",
                      latitude: "",
                      longitude: "",
                    }}
                    validationSchema={Yup.object().shape({
                      name: Yup.string().required("Name is required"),
                      company: Yup.string().required("Company is required"),
                      address: Yup.string().required("Address is required"),
                      zipcode: Yup.string().required("Zipcode is required"),
                      city: Yup.string().required("City is required"),
                      state: Yup.string().required("State is required"),
                      tax: Yup.string().required("Tax is required"),
                      latitude: Yup.string().required("Latitude is required"),
                      longitude: Yup.string().required("Longitude is required"),
                    })}
                    innerRef={(e) => (this.formikRef = e)}
                  >
                    {(formikOptions) => {
                      let {
                        touched,
                        errors,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        values,
                      } = formikOptions;

                      return (
                        <form className="theme-form" onSubmit={handleSubmit}>
                          <Row>
                            <Col sm="6">
                              <FormGroup>
                                <Label>Name *</Label>
                                <Input
                                  className="form-control"
                                  type="text"
                                  name="name"
                                  placeholder="Location Name"
                                  value={values.name}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <span style={{color: "red"}}>
                                  {touched.name && errors.name}
                                </span>
                              </FormGroup>
                            </Col>

                            <Col sm="6">
                              <FormGroup>
                                <Label>Company *</Label>
                                <Input
                                  className="form-control"
                                  type="select"
                                  name="company"
                                  placeholder="Company"
                                  value={values.company}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                >
                                  {companies.map((item) => (
                                    <option value={item.UNIQUE_ID}>{item.NAME}</option>
                                  ))}
                                </Input>

                                <span style={{color: "red"}}>
                                  {touched.company && errors.company}
                                </span>
                              </FormGroup>
                            </Col>
                          </Row>

                          <Row>
                            <Col sm="12">
                              <FormGroup>
                                <Label>Address</Label>
                                <Input
                                  className="form-control"
                                  type="text"
                                  name="address"
                                  placeholder="Line One"
                                  value={values.address}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />

                                <span style={{color: "red"}}>
                                  {touched.address && errors.address}
                                </span>
                              </FormGroup>
                            </Col>
                          </Row>

                          <Row>
                            <Col sm="4">
                              <FormGroup>
                                <Label>City *</Label>
                                <Input
                                  className="form-control"
                                  type="text"
                                  name="city"
                                  placeholder="City"
                                  value={values.city}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />

                                <span style={{color: "red"}}>
                                  {touched.city && errors.city}
                                </span>
                              </FormGroup>
                            </Col>

                            <Col sm="4">
                              <FormGroup>
                                <Label>State *</Label>
                                <Input
                                  className="form-control"
                                  type="text"
                                  name="state"
                                  placeholder="State"
                                  value={values.state}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />

                                <span style={{color: "red"}}>
                                  {touched.state && errors.state}
                                </span>
                              </FormGroup>
                            </Col>

                            <Col sm="4">
                              <FormGroup>
                                <Label>Zipcode *</Label>
                                <Input
                                  className="form-control"
                                  type="text"
                                  name="zipcode"
                                  placeholder="Zipcode"
                                  value={values.zipcode}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />

                                <span style={{color: "red"}}>
                                  {touched.zipcode && errors.zipcode}
                                </span>
                              </FormGroup>
                            </Col>
                          </Row>

                          <Row>
                            <Col sm="4">
                              <FormGroup>
                                <Label>Latitude *</Label>
                                <Input
                                  className="form-control"
                                  type="text"
                                  name="latitude"
                                  placeholder="Latitude"
                                  value={values.latitude}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />

                                <span style={{color: "red"}}>
                                  {touched.latitude && errors.latitude}
                                </span>
                              </FormGroup>
                            </Col>

                            <Col sm="4">
                              <FormGroup>
                                <Label>Longitude *</Label>
                                <Input
                                  className="form-control"
                                  type="text"
                                  name="longitude"
                                  placeholder="Longitude"
                                  value={values.longitude}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />

                                <span style={{color: "red"}}>
                                  {touched.longitude && errors.longitude}
                                </span>
                              </FormGroup>
                            </Col>

                            <Col sm="4">
                              <FormGroup>
                                <Label>Tax Rate *</Label>
                                <Input
                                  className="form-control"
                                  type="text"
                                  name="tax"
                                  placeholder="Tax Rate"
                                  value={values.tax}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />

                                <span style={{color: "red"}}>
                                  {touched.tax && errors.tax}
                                </span>
                              </FormGroup>
                            </Col>
                          </Row>

                          <Row>
                            <Col>
                              <FormGroup className="mb-0">
                                <Button
                                  color="primary"
                                  type="submit"
                                  className="mr-3"
                                >
                                  Create Location
                                </Button>

                                <Link to={`${process.env.PUBLIC_URL}/products`}>
                                  <Button color="danger">Cancel</Button>
                                </Link>
                              </FormGroup>
                            </Col>
                          </Row>
                        </form>
                      );
                    }}
                  </Formik>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Fragment>
    );
  }
}

export default setupReduxConnection(["shop"])(NewLocationPage);
