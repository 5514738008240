const AsYouTypeFormatter = require("google-libphonenumber").AsYouTypeFormatter;
const phoneUtil = require("google-libphonenumber").PhoneNumberUtil.getInstance();

export function isValidPhone(raw) {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parse(raw, "US"));
  } catch (e) {
    return false;
  }
}

export function getFormattedPhone(number) {
  const formatter = new AsYouTypeFormatter("US");
  let formatted;

  try {
    number
      .replace(/-/g, "")
      .replace(/ /g, "")
      .split("")
      .forEach((n) => (formatted = formatter.inputDigit(n)));

    return formatted;
  } catch (e) {
    return "no phone provided";
  }
}
