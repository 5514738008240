import React, {Component} from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import DataTable from "react-data-table-component";
import SearchBox from "../../components/search-box/SearchBox";
import Fuse from "fuse.js";

class ProductUsagesModal extends Component {
  state = {open: false, title: "", helperText: "", productUsage: null, search: ""};

  open(title, helperText, productUsage) {
    this.setState({open: true, title, helperText, productUsage});
  }

  render() {
    let {open, title, helperText, productUsage, search} = this.state;

    if(!open || !productUsage){
      return null;
    }

    let filteredLocations = productUsage.LOCATIONS;
    if(search){
      filteredLocations = new Fuse(filteredLocations, {
        keys: ["LOCATION_ID", "LOCATION_NAME"],
        threshold: .2,
      })
        .search(search)
        .map(({item}) => item);
    }

    return (
      <Modal size={"lg"} isOpen={open} toggle={() => this.setState({open: !open, title: "", helperText: "", productUsage: null})} centered>
        <ModalHeader>
          <div>
            {title}
          </div>

          <div>
            <small>{helperText}</small>
          </div>
        </ModalHeader>

        <ModalBody>
          <div style={{marginBottom: 12}}>
            <SearchBox onChange={(value) => this.setState({search: value})} placeholder={"Search By Location or ID"}/>
          </div>

          <DataTable
            columns={[
              {
                name: "ID",
                selector: "LOCATION_ID",
                sortable: true,
                width: "100px"
              },
              {
                name: "Location",
                selector: "LOCATION_NAME",
                sortable: true,
              },
            ]}
            data={filteredLocations}
            defaultSortField="LOCATION_NAME"
            pagination
            noHeader
          />
        </ModalBody>
      </Modal>
    );
  }
}

export default ProductUsagesModal;
