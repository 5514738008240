import React, {Component} from "react";

class Theme extends Component {
  state = {width: 0};

  updateSize = () => {
    this.setState({width: window.innerWidth});
  };

  componentDidMount() {
    window.addEventListener("resize", this.updateSize);

    try {
      if (window.innerWidth <= 991) {
        document.querySelector(".page-wrapper").className =
          "page-wrapper compact-wrapper";
        document.querySelector(".page-body-wrapper").className =
          "page-body-wrapper sidebar-icon";
        document.querySelector(".page-header").className =
          "page-header close_icon";
        document.querySelector(".sidebar-wrapper").className =
          "sidebar-wrapper close_icon";
      }
    } catch (e) {}

    this.updateSize();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateSize);
  }

  render() {
    let {width} = this.state;

    return <div></div>;
  }
}

export default Theme;
