import React, {Component} from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Spinner,
} from "reactstrap";
import Breadcrumbs from "../../layouts/breadcrumb/breadcrumb";
import {request} from "../../utils/request";
import {Formik} from "formik";
import InputFormGroup from "../../components/form-group/input-form-group";
import SweetAlert from "sweetalert2";
import * as Yup from "yup";
import PhoneFormGroup from "../../components/form-group/phone-form-group";

class AccountPage extends Component {
  state = {
    account: null
  };

  componentDidMount = async () => {
    await this.handleFetchAccountInfo();
  }

  handleFetchAccountInfo = async () => {
    const {ID} = this.props.match.params;

    await request(
      "admin/accounts/id/" + ID,
      "GET"
    ).then((response) => {
      this.setState({
        account: response.ACCOUNT
      });
    }).catch((err) => alert("Error fetching account."));
  };

  handleUpdateAccountInfo = async ({fullName, phone, email}) => {
    const {ID} = this.props.match.params;

    phone = phone.replaceAll("-", "");
    phone = phone.replaceAll(" ", "");

    if (phone.length !== 11) {
      alert(`Phone must be 11 digits. ${phone}`)
      return null;
    }

    const payload = {
      FULL_NAME: fullName,
      PHONE: phone,
      EMAIL: email
    }

    await request("admin/accounts/id/" + ID, "PUT", payload)
      .then(async () => {
        await this.handleFetchAccountInfo();

        await SweetAlert.fire({
          title: "Account Updated!",
          text: `You have updated ${fullName}'s Account!`,
          icon: "success",
        });
      })
      .catch(async ({error}) => {
        if (error === "PHONE_DUPLICATE") {
          await SweetAlert.fire({
            title: "Duplicate Phone",
            text: "An account already exists with this phone number.",
            icon: "warning",
          });
        } else if (error === "EMAIL_DUPLICATE") {
          await SweetAlert.fire({
            title: "Duplicate Email",
            text: "An account already exists with this email.",
            icon: "warning",
          });
        } else {
          alert("Error Saving Account. Please refresh and try again.");
        }
      });
  }

  renderEditAccount = () => {
    let {account} = this.state;

    return (
      <Col xl={{size: 8, offset: 2}}>
        <Formik
          onSubmit={this.handleUpdateAccountInfo}
          initialValues={{
            fullName: account.FULL_NAME,
            phone: account.PHONE,
            email: account.EMAIL
          }}
          validationSchema={Yup.object().shape({
            fullName: Yup.string().required("Full Name is required"),
            email: Yup.string().email(),
            phone: Yup.string().required("Phone is required")
          })}
        >
          {(formikOptions) => {
            let {handleSubmit} = formikOptions;

            return (
              <Card>
                <CardBody>
                  <Form onSubmit={handleSubmit}>
                    <InputFormGroup
                      label={"ID"}
                      value={account.ID}
                      disabled={true}
                    />

                    <InputFormGroup
                      label={"Full Name"}
                      name={"fullName"}
                      options={formikOptions}
                    />

                    <PhoneFormGroup
                      label={"Phone"}
                      name={"phone"}
                      options={formikOptions}
                      mask="1-999-999-9999"
                    />

                    <InputFormGroup
                      label={"Email"}
                      name={"email"}
                      options={formikOptions}
                    />

                    <Button color="primary" block type={"submit"}>
                      Update Personal Account Info
                    </Button>
                  </Form>
                </CardBody>
              </Card>
            );
          }}
        </Formik>
      </Col>
    );
  }

  render() {
    let {account} = this.state;

    return (
      <Container fluid={true}>
        <Breadcrumbs
          parentLink="/accounts"
          parent="Accounts"
          title={account?.FULL_NAME ?? "Loading"}
        />
        {!account ? (
          <div
            style={{display: "flex", justifyContent: "center", marginTop: 150}}
          >
            <Spinner/>
          </div>
        ) : (
          <>
            <Container fluid={true}>
              {this.renderEditAccount()}
            </Container>
          </>
        )}
      </Container>
    );
  }
}

export default AccountPage;
