import React, {Component} from "react";
import {
  Button, Card, CardBody, CardHeader,
  Col,
  Container,
  Row,
  Spinner,
} from "reactstrap";
import Breadcrumbs from "../../layouts/breadcrumb/breadcrumb";
import {request} from "../../utils/request";
import moment from "moment";
import {
  Anchor,
  Archive,
  BookOpen,
  Calendar, CheckSquare,
  Clock, Coffee, CreditCard, DollarSign,
  Gift, Gitlab, Heart, Instagram, Key, Layout,
  MapPin,
  MessageCircle, MessageSquare, MinusCircle,
  Monitor, Send,
  Server,
  Sliders,
  Smartphone, Tag, Target, Trello,
  Truck
} from "react-feather";
import CountWidget from "../../components/data-display/countup-widget/count-widget";
import ProductUsagesModal from "../../modals/product-usages/product-usages-modal";
import ToggleButton from "react-toggle-button";
import SearchBox from "../../components/search-box/SearchBox";
import DataTable from "react-data-table-component";
import Fuse from "fuse.js";

class ProductUsagesPage extends Component {
  state = {
    activeLocations: null,
    primaryByLocation: {},
    primaryByUsage: {},
    secondaryLoading: false,
    secondaryByLocation: {},
    secondaryByUsage: {},
    showCounts: false,
    locationSearch: ""
  };

  componentDidMount = async () => {
    await this.handleFetchPrimaryUsages();
  }

  handleFetchPrimaryUsages = async () => {
    await request(
      "admin/usages/primary",
      "GET",
      {
        DATE_START: moment().subtract(1, "month").valueOf(),
        DATE_END: moment().valueOf()
      }
    ).then((response) => {
      this.setState({
        activeLocations: response.ACTIVE_LOCATIONS,
        primaryByLocation: response.BY_LOCATION,
        primaryByUsage: response.BY_USAGE
      });
    }).catch((err) => alert("Error fetching usages."));
  };

  handleFetchSecondaryUsages = async () => {
    this.setState({secondaryLoading: true})

    await request(
      "admin/usages/secondary",
      "GET",
      {
        DATE_START: moment().subtract(1, "month").valueOf(),
        DATE_END: moment().valueOf()
      }
    ).then((response) => {
      this.setState({
        secondaryLoading: false,
        secondaryByLocation: response.BY_LOCATION,
        secondaryByUsage: response.BY_USAGE
      });
    }).catch((err) => alert("Error fetching secondary usages."));
  };

  renderUsageStat = (title, helperText, productUsage, icon) => {
    const {showCounts} = this.state;

    const percentage = productUsage.PERCENT * 100;

    let backgroundColor = "#5acc35";
    if (percentage < 20) {
      backgroundColor = "#CC3300";
    } else if (percentage < 40) {
      backgroundColor = "#F59966";
    } else if (percentage < 60) {
      backgroundColor = "#F7CC00";
    } else if (percentage < 80) {
      backgroundColor = "#c0e51d";
    }

    return (
      <Col xl={"3"}>
        <div style={{padding: "0 50px", cursor: "pointer"}}
             onClick={() => this.productUsagesModal.open(title, helperText, productUsage)}>
          {showCounts ?
            <CountWidget
              label={title}
              value={productUsage.COUNT}
              icon={icon}
              decimal={0}
              toolTip={helperText}
              backgroundColor={backgroundColor}
            />
            :
            (
              <CountWidget
                label={title}
                value={percentage}
                icon={icon}
                decimal={2}
                suffix={"%"}
                toolTip={helperText}
                backgroundColor={backgroundColor}
              />
            )}
        </div>
      </Col>
    )
  }

  renderPrimaryUsages = () => {
    const {
      MOBILE_ORDERING,
      WEB_ORDERING,
      DELIVERY,
      SCHEDULING,
      TIMESHEETS,
      LOYALTY_REWARDS,
      CASH_DRAWERS,
      SUPPORT_MESSAGING,
      INVENTORY,
    } = this.state.primaryByUsage;

    return (
      <>
        {this.renderUsageStat("Mobile Ordering", "A mobile order was placed in the past month.", MOBILE_ORDERING, Smartphone)}
        {this.renderUsageStat("Web Ordering", "A web order was placed in the past month.", WEB_ORDERING, Monitor)}
        {this.renderUsageStat("Delivery", "A delivery was made in the past month.", DELIVERY, Truck)}
        {this.renderUsageStat("Scheduling", "A shift was scheduled in the past month.", SCHEDULING, Calendar)}
        {this.renderUsageStat("Timesheets", "A timesheet was clocked in the past month.", TIMESHEETS, Clock)}
        {this.renderUsageStat("Loyalty Rewards", "A loyalty reward was redeemed in the past month.", LOYALTY_REWARDS, Gift)}
        {this.renderUsageStat("Cash Drawers", "A cash drawer was opened in the past month.", CASH_DRAWERS, Server)}
        {this.renderUsageStat("Support Messaging", "A customer support text was sent in the past month.", SUPPORT_MESSAGING, MessageCircle)}
        {this.renderUsageStat("Inventory", "Inventory was updated in the past month.", INVENTORY, Sliders)}
      </>
    )
  }

  renderSecondaryUsages = () => {
    const {
      KIOSK_ORDERING,
      EMPLOYEE_MEMOS,
      EMPLOYEE_MESSAGING,
      MARKETING_CAMPAIGNS,
      MARKETING_EVENTS,
      FUNDRAISERS,
      NEW_POSTS,
      COUPONS,
      PROMOTIONS,
      TICKET_DISCOUNTS,
      DIGITAL_GIFT_CARDS,
      PHYSICAL_GIFT_CARDS,
      CUSTOM_PAYMENTS,
      BILLING_PAYMENTS,
      CUSTOM_PRODUCTS,
      LABELS,
      CHECKLISTS,
      INGREDIENTS,
    } = this.state.secondaryByUsage;

    if (KIOSK_ORDERING) {
      return (
        <>
          {this.renderUsageStat("Kiosk Ordering", "A kiosk order was placed in the past month.", KIOSK_ORDERING, BookOpen)}
          {this.renderUsageStat("Employee Memos", "An employee memo was sent in the past month.", EMPLOYEE_MEMOS, Send)}
          {this.renderUsageStat("Employee Messaging", "Employee exchanged messages in the past month.", EMPLOYEE_MESSAGING, MessageSquare)}
          {this.renderUsageStat("Marketing Campaigns", "An email or text marketing campaign was sent the past month.", MARKETING_CAMPAIGNS, Layout)}
          {this.renderUsageStat("Marketing Events", "A marketing event was created in the past month.", MARKETING_EVENTS, DollarSign)}
          {this.renderUsageStat("Fundraisers", "An fundraiser was donated to in the past month.", FUNDRAISERS, Heart)}
          {this.renderUsageStat("News Posts", "A news post was created in the past month.", NEW_POSTS, Instagram)}
          {this.renderUsageStat("Coupons", "A location created a coupon at any time.", COUPONS, Target)}
          {this.renderUsageStat("Promotions", "A promotion was created in the past month.", PROMOTIONS, Key)}
          {this.renderUsageStat("Ticket Discounts", "A ticket discount was used in the past month.", TICKET_DISCOUNTS, MinusCircle)}
          {this.renderUsageStat("Digital Gift Cards", "A digital gift card was used the past month.", DIGITAL_GIFT_CARDS, Trello)}
          {this.renderUsageStat("Physical Gift Cards", "A physical gift card in the past month.", PHYSICAL_GIFT_CARDS, CreditCard)}
          {this.renderUsageStat("Custom Payments", "A custom payment type was used in the past month.", CUSTOM_PAYMENTS, Gitlab)}
          {this.renderUsageStat("Billing Payments", "A billing account was used as a payment type in the past month.", BILLING_PAYMENTS, Archive)}
          {this.renderUsageStat("Custom Products", "A custom product was sold in the past month.", CUSTOM_PRODUCTS, Anchor)}
          {this.renderUsageStat("Labels", "A new label was created in the past month.", LABELS, Tag)}
          {this.renderUsageStat("Checklists", "A checklist was created in the past month.", CHECKLISTS, CheckSquare)}
          {this.renderUsageStat("Ingredients", "Ingredient level inventory was used at any time.", INGREDIENTS, Coffee)}
        </>
      )
    }
  }

  renderLocationViewer = () => {
    const {
      primaryByLocation,
      secondaryByLocation,
      locationSearch
    } = this.state;

    let locationArr = [];
    for (const prim in primaryByLocation) {
      let currentItem = {};
      currentItem = {
        LOCATION_ID: primaryByLocation[prim].LOCATION_ID,
        LOCATION_NAME: primaryByLocation[prim].LOCATION_NAME,
        USAGES: primaryByLocation[prim].USAGES
      }

      if (secondaryByLocation[prim]) {
        currentItem.USAGES.concat(secondaryByLocation[prim].USAGES);
      }

      locationArr.push(currentItem);
    }

    console.log("DEBUG locationArr", locationArr)
    if (locationSearch) {
      locationArr = new Fuse(locationArr, {
        keys: ["LOCATION_ID", "LOCATION_NAME"],
        threshold: .2,
      })
        .search(locationSearch)
        .map(({item}) => item);
    }

    return (
      <Card style={{marginTop: 24}}>
        <CardHeader><h4>Usage By Location</h4></CardHeader>

        <CardBody>
          <div style={{marginBottom: 12}}>
            <SearchBox onChange={(value) => this.setState({locationSearch: value})}
                       placeholder={"Search By Location or ID"}/>
          </div>

          <DataTable
            columns={[
              {
                name: "ID",
                selector: "LOCATION_ID",
                sortable: true,
                width: "100px"
              },
              {
                name: "Location",
                selector: "LOCATION_NAME",
                sortable: true,
              },
            ]}
            data={locationArr}
            defaultSortField="LOCATION_NAME"
            pagination
            noHeader
            expandableRows
            expandOnRowClicked
            expandableRowsComponent={<ProductUsagesList/>}
          />
        </CardBody>
      </Card>
    )
  }

  render = () => {
    const {activeLocations, showCounts, secondaryLoading} = this.state;
    const {KIOSK_ORDERING} = this.state.secondaryByUsage;

    return (
      <Container fluid={true}>
        <ProductUsagesModal ref={(e) => (this.productUsagesModal = e)}/>

        <Breadcrumbs
          parent="Admin"
          title={"Product Usages"}
        />

        {(!activeLocations) ? (
          <div
            style={{display: "flex", justifyContent: "center", marginTop: 150}}
          >
            <Spinner/>
          </div>
        ) : (
          <>
            <Container fluid={true}>
              <Row>
                <Col xl={{size: 2, offset: 5}}>
                  <div style={{marginBottom: 12}}>
                    <div>Show Location Counts</div>
                    <ToggleButton
                      value={showCounts}
                      onToggle={(value) => {
                        this.setState({
                          showCounts: !value,
                        });
                      }}
                    />
                  </div>

                  <CountWidget
                    label="Active Locations"
                    value={activeLocations.length}
                    icon={MapPin}
                    decimal={0}
                    toolTip={"Any order was placed by this location in the past month"}
                  />
                </Col>
              </Row>

              <Row>
                {this.renderPrimaryUsages()}
                {this.renderSecondaryUsages()}
              </Row>

              {(!KIOSK_ORDERING) && (
                secondaryLoading ? (
                    <div style={{display: "flex", justifyContent: "center"}}>
                      <Spinner/>
                    </div>)
                  :
                  <Button block outline color={"primary"} onClick={this.handleFetchSecondaryUsages}>Load More</Button>
              )}

              <Row>
                <Col xl={{size: 6, offset: 3}}>
                  {this.renderLocationViewer()}
                </Col>
              </Row>
            </Container>
          </>
        )}
      </Container>
    );
  }
}

const ProductUsagesList = ({data}) => {
  let usages = data.USAGES.sort();
  return (
    <Card>
      <CardBody>
        <Row>
          {usages.map(x => <Col xl={4} style={{marginBottom: 8}}>{snakeToTitle(x)}</Col>)}
        </Row>
      </CardBody>
    </Card>
  )
}

const snakeToTitle = (snakeCase) => {
  let sentence = snakeCase.toLowerCase().split("_");

  for (let i = 0; i < sentence.length; i++) {
    sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
  }

  return sentence.join(" ");
}


export default ProductUsagesPage;
